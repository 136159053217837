@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.track-players {
      .modal-content {
        .modal-body {
          .row {
            padding-bottom: 15px;
            .track-player-row {
              align-items: center;
              display: flex;
              justify-content: space-between;

              .info {
                overflow: hidden;
                display: flex;
                align-items: center;

                img {
                  -webkit-clip-path: circle(42% at 50% 50%);
                  clip-path: circle(42% at 50% 50%);
                  height: auto;
                  margin-right: 7px;
                  overflow-y: hidden;
                  width: 50px;
                }
              }

              .zc-dropdown {
                .zc-dropdown-results {
                  max-height: 100px;
                }
              }
            }
          }
        }

        .modal-footer {
          padding-top: 30px;
        }
      }
    }
  }
}

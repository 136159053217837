@import '~Styles/general/_variables';

.rivals-camp {
  .page-header-container {
    .page-header {
      .ncsa-logo {
        margin-right: 12%;
        width: 8rem;
        z-index: 2;
      }
    }

    .utility-bar {
      .col-md-8 {
        padding: 0 50px;
      }

      .rivals-camp-nav-filters {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .dropdown-filter {
          margin-right: 5px;
        }

        .input {
          width: 30%;

          input {
            height: 44px;
          }
        }
      }
    }
  }
}

@import '../functions/_svg-uri';
@import '../mixins/typography';

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/light';

.icon {
  color: $black-base;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  font-size: $twenty;

  &.athletic_net {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: $fourteen;
    height: $fourteen;
    width: $fourteen;
    &:before {
      content: '\e90b';
    }
  }

  &.hudl {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: 13px;
    height: $twelve;
    width: $twelve;
    &::before {
      content: '\e910';
    }
  }

  &.nxgn {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: 13px;
    height: $twelve;
    width: $twelve;
    &::before {
      content: '\e911';
    }
  }

  &.national_combine {
    @include set-zicon-font-props();
    color: $black;
    font-size: 25px;
    height: 25px;
    width: 25px;
    &::before {
      content: '\e904';
    }
  }

  &.national_preps,
  &.npRating {
    @include set-zicon-font-props();

    color: $white-base;
    &::before {
      content: '\e908';
    }
  }

  &.ncsa {
    @include set-zicon-font-props();
    color: $white-base;
    height: 15px;
    width: 15px;
    font-size: 15px;
    &::before {
      content: '\e907';
    }
  }

  &.nike_opening {
    @include set-zicon-font-props();
    color: $black;
    font-size: 6px;
    height: 6px;
    width: $sixteen;
    &::before {
      content: '\e906';
    }
  }

  &.polynesian_bowl_combine {
    @include set-zicon-font-props();
    color: $white-base;
    height: 24px;
    width: 24px;
    &::before {
      content: '\e905';
    }
  }

  &.rating247,
  &.twoFourSeven {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: 7px;
    width: 19px;
    height: 8px;
    &::before {
      content: '\e900';
    }
  }

  &.reigning_champs_experiences {
    @include set-zicon-font-props();
    color: $black;
    font-size: $fourteen;
    height: 15px;
    width: 15px;
    &::before {
      content: '\e90d';
    }
  }

  &.rivals,
  &.rivalsRating {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: $ten;
    height: $ten;
    width: $twelve;
    &::before {
      content: '\e903';
    }
  }

  &.rivals_camp,
  &.rivals_combine {
    @include set-zicon-font-props();
    color: $white-base;
    font-size: $fourteen;
    height: $fourteen;
    width: $fourteen;
    &::before {
      content: '\e902';
    }
  }

  &.white {
    color: $white-base;
  }
}

.icon-holder {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  align-items: center;
  background-color: $white-base;
  border: 1px solid $black-base;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 25px;
  justify-content: center;
  padding: 6px;
  width: 25px;
  z-index: 100;

  &.grey-bg {
    background-color: $grey-darker;
    border: none;

    .icon {
      color: $white-base;
    }
  }

  &.hudl-bg {
    background-color: #ff6601;

    .icon {
      color: $white-base;
    }
  }

  &.large {
    height: 50px;
    width: 50px;

    .track_and_field {
      text-align: center;
      font-size: $twenty-four;
      height: $twenty-four;
      width: $twenty-four;
    }
  }

  &.national_preps-bg {
    background-color: #07662b;

    .icon {
      color: $white-base;
    }
  }

  &.ncsa-bg {
    background-color: #0f2b5b;

    .icon {
      color: $white-base;
    }
  }

  &.polynesian_bowl_combine-bg {
    background-color: #026838;
  }

  &.purple-bg {
    background-color: $purple-base;
    border: 2px solid $white-base;
    color: $white;

    .icon {
      color: $white-base;

      .icon {
        color: $white-base;
      }
    }
  }

  &.red-bg {
    background: none;
    border-color: $red-base;

    .icon {
      font-size: $eight;
      color: $red-base;
      height: $eight;
      width: $eight;
    }
  }

  &.rivals-bg {
    background-color: #1574ca;

    .icon {
      color: $white-base;
    }
  }

  &.rivals_camp-bg,
  &.rivals_combine-bg {
    background-color: #345686;

    .icon {
      color: $white-base;
    }
  }

  &.twoFourSeven-bg {
    background-color: #115594;

    .icon {
      color: $white-base;
    }
  }
}

.dash-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  border: 1px dashed $grey-dark;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: $fifteen;
  width: $fifteen;
}

.circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  align-items: center;
  border: 1px solid $white-base;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  height: $fifteen;
  width: $fifteen;

  &.white-bg {
    .icon {
      text-align: center;
      color: $white-base;
      font-size: $eight;
      height: $eight;
      width: $eight;
    }
  }
}

.verified-holder {
  height: 15px;
  width: 15px;
}

@import '~Styles/general/_variables';
#activity-dropdown {
  button[role='menuitem']:focus,
  button[role='menuitem']:hover {
    color: $white;
    background-color: $black-light;
  }

  .icon {
    text-align: center;
  }
}

@import url('https://fonts.googleapis.com/css?family=Chivo:400,700,900');

// Primary Colors
$black: #000000;
$grey: #4a4a4a; // used for fonts
$light-grey: #e2e5ec;
$purple: #754681; // main zcruit color
$white: #ffffff;

//BLACK
$black-light: #52565c;
$black-medium: #363c44;
$black-base: #49494a;
$black-dark: #2c3139;
$black-darkest: #363a41;

//BLUE
$blue-base: #101d49;
$blue-dark: darken($blue-base, 10%);

//BLUE GREY
$blue-grey-base: #52798f;
$blue-grey-light: lighten($blue-grey-base, 52%);
$blue-grey-med: #cfd7db;
$blue-grey-dark: darken($blue-grey-base, 10%);

//GREEN
$green-base: #67a91f;
$green-dark: darken($green-base, 10%);

//PURPLE
$purple-base: #754681;
$purple-base-transparent: rgba(117, 70, 129, 0.2);
$purple-dark: darken($purple-base, 10%);
$purple-input: #74587a;
$purple-medium: #846c89;
$purple-light: #46134e;
$purple-lighter: #ad95af;
$purple-light-nav: #96809b;
$purple-gradient-dark: linear-gradient(315deg, #754681 0%, #46134e 100%);
$purple-gradient-light: linear-gradient(45deg, #512c57 0%, #795284 100%);

//GREY
$grey-icon-holder: #cccccc;
$grey-light: #d6d6d6;
$grey-base: #e9edef;
$grey-dark: #9b9b9b;
$grey-darker: #949494;
$grey-darkest: #6f7479;
$grey-advanced-filters: rgba(216, 216, 216, 0.1);
$grey-advanced-filters-dropdown: #52565d;
$grey-icon: #474747;

//RED
$red-base: #da0202;
$red-dark: darken($red-base, 10%);

//YELLOW
$yellow-base: #f5a623;
$yellow-dark: darken($yellow-base, 10%);

//WHITE
$white-base: #ffffff;
$white-med: #cfd7db;
$white-dark: #e2e2e2;
$white-light: #efefef;
$white-lightest: #f8f8f8;

// Fonts
$main-fonts: Chivo, Arial;

// Sizes (used in paddings)
$four: 0.25rem;
$eight: 0.5rem;
$ten: 0.625rem;
$twelve: 0.75rem;
$thirteen: 0.8125rem;
$fourteen: 0.875rem;
$fifteen: 0.938rem;
$sixteen: 1rem;
$eighteen: 1.125rem;
$twenty: 1.25rem;
$twenty-four: 1.5rem;
$twenty-eight: 1.75rem;
$thirty-two: 2rem;
$forty: 2.5rem;
$forty-eight: 3rem;
$fifty-six: 3.5rem;
$sixty-four: 4rem;

// Weights
$regular: 400;
$bold: 700;
$extra-bold: 900;

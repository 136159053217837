@import '~Styles/general/_variables';

.school-image {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 2px 8px 1px rgba(111, 109, 109, 0.7);
  -moz-box-shadow: 0 2px 8px 1px rgba(111, 109, 109, 0.7);
  box-shadow: 0 2px 8px 1px rgba(111, 109, 109, 0.7);

  align-items: center;
  background: $white-base;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  padding: 10px;
  position: relative;
  width: 50px;

  &.image-only {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    background: none;
    margin: 0;
    padding: 0;
    width: auto;
  }

  img {
    height: auto;
    width: 30px;
  }

  .check-holder {
    position: absolute;
    right: -3px;
    background: none;
    bottom: 1px;

    .icon {
      background-color: transparent;
      border: 1px solid $white-base;
      border-radius: 50%;
      color: $green-base;
    }
  }
}

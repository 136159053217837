@import '~Styles/general/_variables';

.rivals-camp {
  .event-list {
    .event-single {
      &.event-single__container {
        padding: 0 15px;
        margin-bottom: 30px;

        .btn {
          align-items: center;
          color: $grey;
          display: flex;
          font-size: 0.75rem;
          padding: 0;
          width: 100%;
          background: none;
          letter-spacing: 1px;

          &.btn-secondary {
            color: $grey;
          }

          &.transparent {
            background: none;
            margin-bottom: 15px;
            padding: 0;
            text-transform: uppercase;
          }
        }
      }

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;

        span {
          margin: 0 15px 0 7px;
        }

        .icon-holder {
          border: 0;
          height: 35px;
          width: 35px;

          .calendar {
            height: 17px;
            width: 17px;
          }
        }
      }
    }
  }
}

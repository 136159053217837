.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

.visual-cue {
  opacity: 0;
  transition: opacity 0.2s;
  margin: 2px;
}

a:hover,
a:focus {
  .visual-cue {
    opacity: 1;
  }
}

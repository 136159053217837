@import '~Styles/general/_variables';
@import '~Styles/functions/_svg-uri';

.filter {
  .metric {
    border-bottom: 1px solid $black-light;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;

    .close-bg {
      background-color: $grey-dark;
      cursor: pointer;
      height: 16px;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;

      .icon {
        font-size: $ten;
        height: 9px;
        width: 8px;
      }
    }

    .no-verified,
    .verified {
      margin-bottom: 15px;
      position: relative;

      label {
        color: $grey-dark;
        font-size: $thirteen;
        font-weight: normal;
      }
    }

    .no-verified {
      margin-bottom: 0;
    }

    .measureable-container {
      display: flex;
      flex-direction: row;

      .equivalence-button {
        margin: 0;

        input {
          width: 50px;
          &:first-of-type {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        span {
          font-size: $thirteen;
        }

        &:before {
          background-color: $grey-dark;
          color: $white;
          padding: 10px;
          position: relative;
          top: 0;
          left: 0;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          max-height: 35px;
          border: 1px;
          padding-top: 6px;
        }

        &.less-than {
          &:before {
            content: '\2264';
          }
        }

        &.greater-than {
          &:before {
            content: '\2265';
          }
        }
      }

      label {
        display: inline-flex;
        align-items: center;
        font-weight: 100;
        font-style: italic;
        max-width: 70%;

        span {
          margin: 0 10px;
        }
      }
    }
  }

  .zc-dropdown {
    .zc-dropdown-opener {
      background-color: $black-light;
      border: none;
      padding: 8px 10px;

      .icon {
        color: $grey-dark;
      }
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.dropdown-filter {
  align-items: center;
  display: flex;

  .dropdown-filter-button {
    line-height: 1.9;
    width: 100%;
    text-align: left;

    &:focus {
      @include outline();
    }

    .icon {
      color: $white-base;
      font-size: $ten;
      height: $ten;
      margin-left: 15px;
      width: $ten;
    }
  }

  .dropdown-checkbox-menu {
    background-color: $black-dark;

    .parent-dropdown {
      width: 100%;
    }

    .parent-toggle {
      font-weight: 100;
      font-size: 0.875rem;
      min-height: 0;
      padding: 0;
    }

    button {
      &.parent-toggle {
        &.btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          text-align: left;
          padding: 0;
          color: $grey-dark;

          .icon {
            background-color: $grey-dark;
          }
          &.selected {
            background-color: #373a41;
          }
        }
      }
    }
  }
}

.dropdown-checkbox-menu {
  -webkit-box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);

  background-color: $black-dark;
  border-radius: 0;
  border: none;
  color: $grey-light;
  max-height: 500px;
  right: 0;

  &:before {
    display: block;
    position: absolute;
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250"><title>dropdown-pointer</title><g id="dropdown-pointer" data-name="dropdown-pointer"><g id="dropdown-pointer"><polygon points="0,100 75,25 150,100" fill="rgb(44,49,55)" /></g></g></svg>');
    height: 4rem;
    width: 4rem;
    top: 0;
    right: 0;
    transform: translateY(-1.5rem);
  }

  &.nested-dropdown {
    z-index: 1500;
    background-color: $black-dark;
    &:before {
      display: none;
    }
  }

  .dropdown-inner-container {
    overflow-y: scroll;
    max-height: 500px;

    &.column-count-2 {
      column-count: 2;
    }

    & > div:focus {
      & > div.dropdown-checkbox-input,
      .no-toggle.parent-dropdown {
        @include outline();
      }
    }
  }

  .dropdown-item {
    background-color: $black-dark;
    color: $white-base;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    outline: none;
    padding: 7px 15px;

    &:last-of-type {
      margin: 0;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $black-dark;
    }

    &.highlight {
      border-bottom: 1px solid $black-light;
    }

    .dropdown-checkbox-input {
      min-height: 0;
      padding: 0;

      label {
        font-size: 0.875rem;
      }
    }

    button {
      &.parent-toggle {
        &.btn {
          width: 100%;
          text-align: left;
          padding-left: 0;
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.video-js {
  &.vjs-fluid {
    &:hover {
      .vjs-big-play-button {
        background-color: $purple-base;
        transition: background-color 150ms;
      }
    }

    .vjs-big-play-button {
      background-color: rgba(255, 255, 255, 0.3);
      left: calc(50% - 1.5em);
      top: calc(50% - 0.5em);
      transition: background-color 150ms;
    }
  }
}

@import '~Styles/general/_variables';

.dropdown-checkbox-menu.filter-dropdown {
  -webkit-box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.5);

  background-color: $black-light;
  border-radius: 0;
  border: none;
  color: $grey-light;
  max-height: 400px;
  right: 0;
  padding: 0;
  overflow: hidden;

  &:before {
    display: block;
    position: absolute;
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250"><title>dropdown-pointer</title><g id="dropdown-pointer" data-name="dropdown-pointer"><g id="dropdown-pointer"><polygon points="0,100 75,25 150,100" fill="rgb(44,49,55)" /></g></g></svg>');
    height: 4rem;
    width: 4rem;
    top: 0;
    right: 0;
    transform: translateY(-1.5rem);
  }

  &.nested-dropdown {
    background-color: $black-light;
    width: 15%;

    &:before {
      display: none;
    }
  }

  .dropdown-inner-container {
    overflow-y: scroll;
    max-height: 400px;
    background-color: $black-light;

    &.column-count-2 {
      column-count: 2;
    }
  }

  .dropdown-item {
    background-color: $black-light;
    color: $white-base;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    outline: none;
    padding: 0;

    &.hidden {
      display: none;
    }

    &:last-of-type {
      margin: 0;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $black-light;
    }

    &.highlight {
      background-color: lighten($black-light, 5%);
    }

    &.last-child {
      padding: 5px 15px;
    }

    .dropdown-checkbox-input {
      min-height: 0;
      padding: 0;

      label {
        font-size: 0.875rem;
      }
    }

    .parent-dropdown-container {
      width: 100%;

      .parent-dropdown {
        width: 100%;

        div {
          &.parent-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $grey-dark;
            background-color: transparent;
            width: 100%;
            text-align: left;
            font-weight: 100;
            font-size: 0.875rem;
            min-height: 0;
            padding: 5px 15px;

            .caret-right {
              color: $grey-dark;
              right: 0;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.parent-dropdown {
  > .parent-toggle {
    &.hidden {
      height: 0;
      width: 0;
      position: absolute;
      padding: 0;
      opacity: 0 !important;
    }
  }
}

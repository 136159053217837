@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.filters {
  -webkit-box-shadow: 6px 1px 14px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 1px 14px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 1px 14px 1px rgba(0, 0, 0, 0.75);

  background-color: $black-dark;
  color: $grey-dark;
  height: 100%;
  padding: 30px 0 30vh;

  &.sticky-top {
    overflow-y: scroll;
    z-index: 3;
    -ms-overflow-style: none;
    min-height: 100vh;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .filter-functions-container {
    text-align: center;
    padding-bottom: 5px;
  }

  .link-grey {
    color: $grey-dark;
  }

  .link-white {
    color: $white;
  }

  .button-filters {
    a {
      display: block;
      text-align: center;
    }

    .btn {
      margin-bottom: 7px;
      padding: 10px 0;
      width: 100%;
    }

    > .save-button:focus {
      @include outline();
    }
  }
  .button-filter-col {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 12vh;
    display: flex;
    justify-content: center;
    background-color: $black-dark;

    .button-filters {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      background-color: $black-dark;
      width: 100%;
      border-top: 1px solid $grey-darkest;
      margin-top: 1px;
      padding-top: 2vh;

      .btn {
        width: 100%;
      }
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.filter {
  padding: 0 15px;
  margin-bottom: 30px;

  &.boolean {
    label:focus-within {
      @include outline();
    }

    label {
      margin-bottom: 0;
      flex-direction: row-reverse;
      justify-content: space-between;

      .checkbox {
        position: unset;
        left: unset;
        z-index: unset;
        opacity: unset;
      }
    }
  }

  > .btn {
    letter-spacing: 1px;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: $grey-dark;
  }

  label.transparent {
    cursor: pointer;
    color: #9b9b9b;
    background: none;
    margin-bottom: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
  }

  .btn {
    align-items: center;
    color: $grey-dark;
    display: flex;
    font-size: $twelve;
    padding: 0;
    width: 100%;

    &[aria-expanded]:focus {
      @include outline();
    }

    &:hover {
      color: $grey-dark;
    }

    &.transparent {
      background: none;
      margin-bottom: 15px;
      padding: 0;
      text-transform: uppercase;

      &.disabled {
        background-color: transparent;
        opacity: 1;
      }
    }

    &.active {
      background-color: $purple;
    }

    &.btn-primary {
      background-color: $purple-medium;
    }

    &.btn-secondary {
      color: $white;
    }

    &.toggleable {
      display: inline-block;
    }

    .icon {
      &.caret-down {
        -webkit-transition: transform 350ms;
        -moz-transition: transform 350ms;
        -ms-transition: transform 350ms;
        -o-transition: transform 350ms;
        transition: transform 350ms;

        color: $white;
        font-size: $eighteen;
        margin-left: auto;
        transform: rotate(0deg);
        width: 20px;

        &.flip {
          -webkit-transition: transform 350ms;
          -moz-transition: transform 350ms;
          -ms-transition: transform 350ms;
          -o-transition: transform 350ms;
          transition: transform 350ms;

          transform: rotate(-180deg);
        }
      }

      &.search {
        background-position: center;
        background-color: $grey-darkest;
        width: 25px;
        height: 25px;
      }
    }
  }

  .btn-sub-label {
    display: block;
    margin-top: 2px;
    font-style: italic;
    font-size: 10px;
  }

  .collapse,
  .collapsing {
    display: grid;
    grid-gap: 3px 0;
    grid-template-columns: repeat(auto-fill, 100%);
    justify-content: space-between;

    &.half {
      grid-template-columns: repeat(auto-fill, 49%);
    }

    &.third {
      grid-template-columns: repeat(auto-fill, 32%);
    }

    &.quarter {
      grid-template-columns: repeat(auto-fill, 24%);
    }

    .btn {
      display: flex;
      text-align: center;
    }
  }

  .dropdown-filter-button {
    background-color: $black-light;
    color: $grey-dark;
  }

  .dropdown-filter-menu {
    background-color: $black-light;
  }

  .dropdown-filter-button-interior {
    background-color: transparent;
    width: 100%;
  }

  .dropdown {
    button {
      background-color: $black-light;
      height: 35px;
      border-radius: 4px;
      padding: 10px;
      &.tag-remove {
        background-color: $purple-base;
      }
    }

    .dropdown-menu {
      background-color: $black-light;
      border-radius: 0;
      padding: 0;
      width: 100%;
    }

    .dropdown-item {
      background-color: transparent;
      border-radius: 0;
      outline: none;
    }

    label {
      font-weight: 400;
    }
  }

  input {
    background-color: $black-light;
    border: 1px solid #d6d6d6;
    border-color: $black-light;
    color: $white-base;
    width: 100%;

    &[type='checkbox'] {
      width: auto;
    }

    &[type='text'],
    &[type='number'] {
      height: 35px;
      border-radius: 4px;
      padding: 10px;
    }

    &[type='number'] {
      padding-right: 5px;
      padding-left: 5px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    *:focus {
      @include outline();
    }
  }

  .school-filter-btn {
    .checkbox:focus + label:before {
      @include outline();
    }
  }
}

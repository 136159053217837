@import '~Styles/general/_variables';

.clickable-tooltip {
  max-width: 245px;
  color: white;
  font-size: $fourteen;
  text-align: left;

  a {
    text-decoration: underline;
    color: $grey-dark;
    font-size: $ten;
  }
}

.clickable-tooltip-target {
  cursor: pointer;
}

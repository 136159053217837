@import '~Styles/general/_variables';

.tooltip-list {
  text-align: left;
  h3 {
    text-align: center;
  }
  .rating-preview-rule {
    background-color: $grey-dark;
  }
  .source-rating-item {
    color: $grey-dark;
    display: flex;
    align-items: center;
    .icon-holder {
      display: inline-flex;
      margin-right: 10px;
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.account {
  > .page-content {
    min-height: calc(100% - 65px);
  }

  .page-content {
    .box-container {
      padding: 50px 40px 0;
      min-height: 80vh;
    }

    .zcruit-logo {
      padding-top: 0;
    }
  }
}

.add-list-modal {
  .filter {
    .collapse,
    .collapsing {
      .row {
        margin: 0;

        .col-md-4 {
          padding: 0;
        }
      }
    }

    .collapse {
      button {
        &:focus {
          @include outline();
        }
      }
    }
  }

  .button-filters {
    .row {
      margin: 0;

      .filter-functions-container {
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  .navigation-search {
    .search-area {
      input {
        &.expanded {
          width: 100%;
        }
      }
    }

    .search-results {
      top: 38px;
      z-index: 3;
    }
  }
}

.modal {
  &.confirm-delete-modal {
    .col-md-10 {
      &.modal-query-container {
        display: flex;
        justify-content: center;
      }

      &.modal-button-container {
        display: flex;
        justify-content: center;
        .btn {
          &:first-of-type {
            margin-right: 15px;
          }
        }
      }

      p {
        font-size: $eighteen;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
}

button:focus,
.modal-button:focus {
  @include outline();
}

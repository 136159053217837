@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.academics-page {
              .box {
                min-height: 200px;
              }

              .academics-preview {
                display: flex;
                font-size: 2rem;
                align-items: center;

                .icon-holder {
                  margin-left: 0.5rem;
                }
              }

              .academics-no-data {
                font-size: 1.2rem;
                color: $grey-light;
                margin-top: 10%;
                text-align: center;
              }

              .dash-circle {
                margin-left: 7px;
              }

              .btn {
                &.btn-secondary {
                  background-color: transparent;
                  padding: 0 0 15px 0;
                  float: right;

                  .icon {
                    &.caret-down {
                      -webkit-transition: transform 350ms;
                      -moz-transition: transform 350ms;
                      -ms-transition: transform 350ms;
                      -o-transition: transform 350ms;
                      transition: transform 350ms;

                      color: $grey-darkest;
                      margin-left: auto;
                      transform: rotate(0deg);
                    }

                    &.flip {
                      -webkit-transition: transform 350ms;
                      -moz-transition: transform 350ms;
                      -ms-transition: transform 350ms;
                      -o-transition: transform 350ms;
                      transition: transform 350ms;

                      transform: rotate(-180deg);
                    }
                  }
                }
              }

              .table-container {
                table {
                  td,
                  th {
                    border: none;
                    padding: 0.75rem 0.5rem;
                    text-align: center;
                  }

                  thead {
                    tr {
                      background-color: $black-medium;
                      color: $white-base;
                    }
                  }

                  tbody {
                    tr {
                      &.even {
                        background-color: $white-light;
                      }

                      td {
                        .icon-holder {
                          margin: 0 auto;
                        }

                        .verified-holder {
                          display: flex;
                          justify-content: center;
                          margin: 0 auto;

                          .verified {
                            background-color: inherit;
                            margin: 0 15px 0 7px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

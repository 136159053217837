@import '~Styles/mixins/_misc';

input {
  &::placeholder {
    color: $white;
  }
}

.input {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  align-items: center;
  background-color: $black-light;
  border: 1px solid $black-light;
  color: $white-base;
  display: flex;
  padding: 0 5px;
  width: 100%;

  &.white {
    background-color: $white-base;
    border: 1px solid $grey-light;

    input {
      background-color: $white-base;
      border: 1px solid $white-base;
      color: $black-base;
    }
  }

  input {
    background-color: $black-light;
    border: 1px solid $black-light;
    color: $white;
    outline: none;
    width: 100%;

    &[type='text'] {
      height: 40px;
    }

    &[type='number'] {
      height: 35px;
      border-radius: 4px;
      padding: 20px 5px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &:focus {
      @include outline();
    }
  }

  .icon {
    &.search {
      font-size: $sixteen;
      color: $white;
      height: $sixteen;
      margin: 0 7px;
      width: $sixteen;
    }
  }
}

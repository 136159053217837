@import '~Styles/general/_variables';

.rivals-camp {
  .event-list {
    .event-single {
      .box-holder {
        .video {
          display: flex;

          &:nth-of-type(odd) {
            padding-right: 7px;
          }

          &:nth-of-type(even) {
            padding-left: 7px;
          }

          .box {
            display: flex;
            margin-bottom: 7px;
            padding: 15px;
            width: 100%;

            a {
              align-items: center;
              display: flex;
              width: 100%;

              p {
                margin: 0;
                width: 75%;
              }

              .icon-holder {
                height: 30px;
                margin-left: auto;
                position: relative;
                width: 30px;

                .play {
                  height: $ten;
                  font-size: $ten;
                  text-align: center;
                  width: $ten;
                }
              }
            }
          }
        }
      }
    }
  }
}

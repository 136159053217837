body {
  color: $grey;
  font-family: $main-fonts;
  font-size: 16px;
  font-weight: $regular;
}

h3 {
  font-size: $twenty-four;
  font-weight: $bold;
}

p {
  font-size: $sixteen;
}

a {
  color: $black;
  font-size: $sixteen;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

label {
  font-size: $sixteen;
  font-weight: $extra-bold;
}

@import '~Styles/general/_variables';

.single-player-video-container {
  font-size: 1rem;

  .title-row {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    position: relative;

    .video-title {
      font-weight: 600;
      text-align: center;
    }

    .icon.back {
      display: block;
      position: absolute;
      left: -6.3rem;
    }

    .back-to-list-link {
      position: absolute;
      left: -75px;
      color: $grey;
      font-size: 1rem;
    }
  }

  .row {
    .video-player-container {
      .video-playing-component {
        font-size: 1rem;
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      height: 95%;
      max-width: 1200px;
      width: 95%;

      .modal-content {
        background-color: $grey-light;
        height: 100%;
        overflow: scroll;
        padding: 0;

        .modal-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;

          .modal-header {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;

            align-items: center;
            background: $purple-gradient-light;
            color: $white-base;
            justify-content: flex-start;
            margin: 0;
            max-height: 60px;
            min-height: 60px;
            padding: 0 45px;
            position: relative;
            width: 100%;

            h1 {
              font-size: $twenty;
              margin: 0;
            }

            .player-page-nav {
              list-style: none;
              margin: 0 0 0 60px;
              padding: 0;

              button {
                display: inline-block;
                padding: 20px 15px;
                border: none;
                background: transparent;

                &:active {
                  outline: none;
                }

                &:focus,
                &.active {
                  transform: skewX(-10deg);

                  span {
                    transform: skewX(10deg);
                  }
                }

                &.active {
                  background: rgba(255, 255, 255, 0.3);
                }

                span {
                  color: $white-base;
                  display: inline-flex;
                  font-size: $thirteen;
                  cursor: pointer;
                }
              }
            }

            .closer {
              cursor: pointer;
              right: 15px;
              position: absolute;
              top: 22px;

              .icon {
                color: $white-base;
                font-size: $twenty;
                height: 20px;
                width: 18px;
              }
            }
          }

          .modal-body {
            background: $grey-light;
            flex-grow: 1;
            margin: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 0;

            &.edit {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

            .secondary-page {
              background: linear-gradient(315deg, $purple-base 0%, $purple-light 100%);
              background-size: 100% 250px;
              margin: 0;
              min-height: 250px;
              position: relative;

              .box-holder {
                position: absolute;
                padding: 0 45px;
                top: 110px;
              }
            }

            .page-title-container {
              align-items: flex-start;
              display: flex;
              margin-bottom: 20px;
              padding: 45px 45px 0;

              .page-title {
                color: $white-base;
                font-size: 1.9rem;
              }

              .page-title-inner {
                margin-right: 10px;
              }

              .page-title-handle {
                color: $white;
                font-size: 1.5rem;
                padding-top: 5px;
              }
            }

            .timeline {
              padding-top: 30px;
            }
          }

          .footer {
            align-items: center;
            background-color: $white-base;
            box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.09);
            display: flex;
            justify-content: flex-start;
            margin-top: 30px;
            padding: 30px 30px 40px;
            width: 100%;

            &.edit-footer {
              min-height: 111px;
              max-height: 111px;
            }

            p {
              font-size: $sixteen;
              font-weight: 900;
              margin-bottom: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}

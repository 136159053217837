@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.timeline {
  margin-bottom: 15px;

  &.row {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .header {
    h3 {
      font-size: $sixteen;
    }
  }

  .timeline-legend {
    padding: 0 0 0 15px;

    .entry {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: space-between;
      margin-bottom: 7px;
      padding: 0 15px 0 0;

      &.main {
        border-bottom: 1px solid $light-grey;
        height: 45px;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      .icon-holder {
        img {
          height: auto;
          margin-right: 7px;
          width: 30px;
        }

        p {
          margin: 0;
        }
      }

      .visit-count {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        align-items: center;
        background-color: $light-grey;
        display: flex;
        font-size: $twelve;
        height: 20px;
        justify-content: center;
        width: 20px;
        text-align: center;
      }
    }

    .header {
      padding: 0 15px 0 0;
    }
  }

  .holder {
    overflow: hidden;
    width: 100%;

    .timeline-entries {
      overflow: scroll;
      padding: 0 15px 0 0;

      .header {
        display: flex;
        justify-content: space-between;
        min-width: 100%;
        width: 1560px;
      }

      .entry {
        height: 30px;
        margin-bottom: 7px;
        min-width: 100%;

        &.main {
          border-bottom: 1px solid $grey-light;
          height: 45px;
          margin-bottom: 15px;
          padding-bottom: 15px;
        }

        .background {
          align-items: center;
          background-color: $white-light;
          display: flex;
          justify-content: space-between;

          .single {
            background-color: $white-light;
            cursor: pointer;
            height: 30px;
            padding-right: 3px;
            width: 30px;

            > div {
              align-items: center;
              display: flex;
              height: 100%;
              justify-content: center;
              width: 100%;

              .icon {
                width: $fourteen;
                height: $fourteen;
                font-size: $fourteen;
                text-align: center;
              }

              span {
                color: $white-base;
              }
            }
          }
        }
      }
    }
  }

  .showMore {
    cursor: pointer;
    padding: $ten;
    text-align: center;
    background: transparent;
    border: 0;
    color: $grey;
    font-size: $sixteen;
    font-weight: $regular;
    margin: $ten;

    &:focus {
      @include outline();
    }
  }
}

.timeline-tooltip {
  &.tooltip {
    .tooltip-inner {
      color: $white-base;
      padding: 0;

      h4 {
        font-size: $thirteen;
      }

      p {
        font-size: $twelve;
        margin: 0;
        max-width: 300px;
      }

      .twitter {
        width: 250px;
        border-bottom: 1px solid $grey-dark;

        .twitter-header {
          background-color: $black-darkest;
          padding: 10px 15px;

          h2 {
            align-items: center;
            display: flex;
            font-size: $twelve;
            justify-content: center;
            margin: 0;

            i {
              margin-right: 7px;
            }
          }
        }

        .twitter-body {
          padding: 10px 20px 20px;

          .person {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 15px;

            .image {
              -webkit-clip-path: circle(42% at 50% 50%);
              clip-path: circle(42% at 50% 50%);

              align-self: baseline;
              margin-right: 7px;
              max-width: 75px;
              width: auto;
            }

            .info {
              width: 80%;

              .name {
                font-size: $thirteen;
                font-weight: $bold;
              }
            }
          }

          .interaction {
            .tweet {
              margin-bottom: 7px;
            }

            .date {
              color: $grey-dark;
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.offer-tooltip {
  &.tooltip {
    .tooltip-inner {
      color: $white-base;
      padding: 0;

      p {
        font-size: $twelve;
        margin: 0;
        max-width: 300px;
        text-align: center;

        &.school {
          font-weight: bold;
        }
      }
    }
  }
}

.offer-clickable-tooltip {
  flex-direction: column;
  float: left;
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  margin-bottom: 7px;
  margin-top: 5px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 7px;
}

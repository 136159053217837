.logo {
  background: url('~Assets/images/zcruit.png') no-repeat;
  height: 71px;
  width: 200px;

  a {
    display: block;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.zcruit-by-ncsa-logo {
  content: url('~Assets/images/zcruit-by-ncsa-logo.png');
  width: 200px;

  a {
    display: block;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.zcruit-footer-logo {
  height: 50px;
}

@import '~Styles/general/_variables';
@import '~Styles/functions/_svg-uri';

.filter {
  .react-dropdown-tree-select {
    .dropdown {
      width: 100%;

      .dropdown-trigger {
        border: none;
        max-height: 100%;
        padding: 0;
        width: 100%;

        .tag-list {
          display: flex;
          flex-wrap: wrap;

          .tag-item {
            margin: 0;

            &:last-of-type {
              order: -1;
              margin-bottom: 7px;
              width: 100%;
            }

            .search {
              border: none;
            }

            .tag {
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              border-radius: 4px;

              align-items: center;
              background: #754681;
              border: none;
              color: #fff;
              display: flex;
              font-size: 0.875rem;
              margin: 0 5px 5px 0;
              padding: 5px 7px;
            }
          }
        }

        &.arrow {
          &.bottom,
          &.top {
            &:after {
              content: '';
              display: none;
            }
          }
        }
      }

      .dropdown-content {
        background: $black-light;
        font-size: 0.875rem;
        max-height: 400px;
        overflow-y: scroll;
        top: 35px;
        width: 100%;

        ul {
          li {
            display: flex;
            align-items: center;
            padding: $four !important;

            i.toggle {
              display: none;
            }

            &.focused {
              background-color: $grey-darkest;
            }
          }
        }
      }
    }
  }
}

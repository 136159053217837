@import '~Styles/general/_variables';

.filter {
  &.advanced {
    padding: 0 15px;

    .collapse,
    .collapsing {
      margin-top: 15px;

      .filter {
        background: $grey-advanced-filters;
        margin: 0;
        padding: 15px 15px 0;

        &.transparent-background {
          background-color: transparent;
        }

        .show {
          padding-bottom: 15px;
        }

        .dropdown button {
          font-size: $sixteen;
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.contact_holder {
                .contact_holder_contact {
                  .contact_holder_contact_contact {
                    .contact-info-piece {
                      align-items: center;
                      border-bottom: 1px solid $grey-dark;
                      display: flex;
                      padding: 15px 0;
                      white-space: pre-wrap;
                      word-break: break-all;

                      &:first-of-type {
                        padding-top: 0;
                      }

                      &:last-of-type {
                        border: none;
                        padding-bottom: 0;
                      }

                      p {
                        margin: 0;
                        font-weight: 700;
                      }

                      .icon-holder {
                        height: 30px;
                        margin-right: 15px;
                        width: 30px;

                        &:last-of-type {
                          height: 25px;
                          margin: 0 0 0 auto;
                          width: 25px;
                        }

                        .icon {
                          text-align: center;
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

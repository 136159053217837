@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.video_holder {
                .video_holder_video {
                  .video_holder_video_video {
                    display: flex;
                    margin-bottom: 7px;

                    .tab-link {
                      align-items: center;
                      border-bottom: 1px solid $grey-dark;
                      cursor: pointer;
                      display: flex;
                      padding: 15px 0;
                      width: 100%;

                      &:first-of-type {
                        padding-top: 0;
                      }

                      &:last-of-type {
                        border: none;
                        padding-bottom: 0;
                      }

                      p {
                        margin: 0 15px 0 0;
                      }

                      .icon-holder {
                        flex-grow: 0;
                        flex-shrink: 0;
                        height: 35px;
                        margin-right: 15px;
                        padding: 0;
                        width: 35px;
                        border: 1px solid $black-base;

                        &.right {
                          background-color: $grey-icon-holder;
                          border: none;
                          height: 25px;
                          margin-left: auto;
                          margin-right: 0;
                          width: 25px;
                        }

                        .play {
                          color: $black-base;
                          font-size: $sixteen;
                          height: $sixteen;
                          width: $sixteen;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.delete-filter-confirm-modal {
  .close-modal-container {
    position: absolute;
    right: 15px;
    top: 15px;

    .close-modal {
      .icon-holder {
        background-color: transparent;
        border: 2px solid $grey-dark;

        .x {
          text-align: center;
          color: $grey-dark;
          font-size: $ten;
          height: $ten;
          width: $ten;
        }
      }
    }
  }

  .btn {
    &.btn-primary {
      &.red {
        background-color: $red-base;
      }
    }
  }
}

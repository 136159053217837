@import '~Styles/general/_variables';

.toggler {
  display: flex;

  .left,
  .right {
    -webkit-transition: background-position 150ms ease-in 1500ms;
    -moz-transition: background-position 150ms ease-in 1500ms;
    -o-transition: background-position 150ms ease-in 1500ms;
    transition: background-position 150ms ease-in 1500ms;

    background-color: $white-base;
    background-position: 0 0;
    border: 1px solid $grey-base;
    padding: 7px 30px;
    width: 50%;

    &.active {
      -webkit-transition: background-position 150ms ease-in 1500ms;
      -moz-transition: background-position 150ms ease-in 1500ms;
      -o-transition: background-position 150ms ease-in 1500ms;
      transition: background-position 150ms ease-in 1500ms;

      background-color: $purple-base;
      background-position: 100% 100%;
      color: $white-base;
    }
  }

  .left {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    border-right: 1px solid $grey-base;
  }

  .right {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .stats-body {
                .type {
                  h3 {
                    .preview {
                      display: inline-flex;
                      align-items: center;
                      margin-left: 0.25rem;
                      font-weight: 400;

                      .verified-holder {
                        .icon {
                          margin-left: 0.25rem;
                        }
                      }
                    }
                  }

                  table {
                    td,
                    th {
                      border: none;
                      padding: 0.75rem 1rem;
                      text-align: center;
                      vertical-align: middle;
                    }

                    thead {
                      tr {
                        background-color: $black-medium;
                        color: $white-base;
                      }
                    }

                    tbody {
                      tr {
                        &.even {
                          background-color: $white-light;
                        }

                        td {
                          .icon-holder {
                            margin: 0 auto;
                          }

                          .verified-holder {
                            display: flex;
                            justify-content: center;
                            margin: 0 auto;
                          }

                          .star {
                            background-color: $grey-dark;
                            height: 20px;
                            margin-left: auto;
                            width: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

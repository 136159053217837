@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.account {
  .table-list {
    border: 1px solid $light-grey;
    -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);

    &.table {
      background-color: $white;
    }

    .list-heading {
      font-size: 25px;

      .title {
        width: 70%;
        h2 {
          margin-bottom: 0;
        }
      }

      .add-button {
        width: 5%;
        .add-new-list:focus {
          @include outline();
        }
      }
    }

    .filter-details-container {
      background-color: $white-light;
    }
  }
}

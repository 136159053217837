@import '~Styles/general/_variables';

// Variables

$brand-primary: #853eb1;
$brand-danger: #e74c3c;
$gray-darkest: #202020;
$gray: #777;
$gray-lighter: #eee;
$placeholder-text: #bbbbbb;
$white: #fff;
$black: #000;

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font('Raleway', '../../../assets/fonts/Raleway-Regular');
@include font('Raleway-SemiBold', '../../../assets/fonts/Raleway-Regular');
@include font('Raleway-Bold', '../../../assets/fonts/Raleway-Regular');

@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

@mixin button-variant($color, $background, $border) {
  font-size: 16px;
  color: $color;
  padding: 13px 70px;
  background-color: $background;
  border: 1px solid $border;
  border-radius: 3px;

  @at-root &.active,
    &:active,
    &:focus,
    &:hover {
    cursor: pointer;
    border-color: darken($border, 12%);
    border-width: 1.5px;
    border-style: solid;

    @if $background != none {
      background-color: darken($background, 10%);
    }
  }
}

.landing-page {
  scroll-behavior: smooth;
  background-color: white;
  font-family: Raleway;
  margin: 0 -15px;
}

.landing-page {
  h4 {
    font-family: Montserrat;
  }

  h2 {
    font-family: Montserrat;
  }

  button {
    background-color: $brand-primary;
    color: $white-base;
    width: 170px;
    height: 40px;
    font-weight: $extra-bold;
    font-family: Montserrat;
  }
}

@media screen and (max-width: 767px) {
  .landing-page {
    p {
      font-size: 12px;
    }

    h2 {
      font-size: 16p;
    }
  }
}

.landing-page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url('~Assets/images/LandingPage/hero-image.png');
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;

  @include background-cover;

  color: white;
  font-family: Montserrat;
  font-weight: normal;

  .intro-text {
    padding-top: 100px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: left;

    .intro-lead-in {
      font-style: italic;
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 25px;
    }

    .intro-heading {
      font-weight: lighter;

      p {
        font-weight: 820;
        font-size: 6em;
      }

      top {
        font-weight: light;
      }
    }

    .intro-text_summary {
      font-size: 25px;
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-page-header {
    height: 50vh;
  }

  .intro-text {
    margin-left: 20vw;
  }
}

// Restyled Primary Buttons

.line-filler {
  transform: scale(0.55);
  margin: 10px 0 40px 0;
  stroke: $brand-primary;
}

section {
  padding: 50px 0;
}

h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}

h3.section-subheading {
  // font-size: 16px
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
}

@media screen and (min-width: 768px) {
  section {
    padding: 50px 0;
  }
}

span.copyright {
  line-height: 40px;
  color: white;

  // @include heading-font
  text-transform: none;
}

ul.quicklinks {
  margin-bottom: 0;
  line-height: 40px;

  // color: white
  // @include heading-font
  text-transform: none;
}

.footer-link {
  color: white;
}

.feature-heading {
  vertical-align: middle;
  text-align: left;
}

.feature-heading_text {
  text-transform: none;
  color: $brand-primary;
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 17px;
  margin-left: 10px;
}

.feature-heading_img {
  margin-top: 20px;
  -webkit-clip-path: inset-rectangle(20% 20% 20% 20%);
  clip: inset-rectangle(20% 20% 20% 20%);
  display: inline-block;
}

.feature-text {
  color: $black;
  text-align: left;
  margin-top: 15px;
  line-spacing: 20px;
  font-size: 18px;
}

.text-muted {
  color: $white-base;
  font-size: 14px;
}

.landing-summary {
  font-size: 18px;
  color: $white-base;
  text-align: justify;
  text-indent: 50px;
}

.landing-page-header {
  height: 100vh;
  width: 100vw;
}

.svg-definitions {
  padding: 0px;
  height: 0px;
  overflow: hidden;
}

#loginNav {
  border: none;
  background: rgba(255, 255, 255, 0);
  margin-bottom: -120px;
  height: 120px;
  font-family: Raleway-Bold;
  width: 100%;
  z-index: 2;

  .container {
    padding-top: 15px;
    width: 100%;
  }

  .nav-logo {
    padding: 5px 5px;
    margin-left: 30px;
    background-size: contain;
  }

  ul#main-nav {
    font-size: 14px;
    font-weight: 700;
    padding: 18px 5px 0 0;
  }

  a {
    color: lighten($white-base, 20%);
  }

  a:hover {
    color: $brand-primary;
    cursor: pointer;
  }

  #loginBtn:hover {
    color: black;
  }
}

button.navbar-toggler {
  background-color: $black;
  margin-top: 10px;
  border: 0;
  width: 43px;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .navbar {
    justify-content: normal;

    button.navbar-toggler {
      margin-left: auto;
    }
  }
}

.navbar-collapse {
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  #features {
    padding-top: 5px;
    padding-bottom: 0;
  }

  #loginNav ul#main-nav {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .container {
    > .navbar-header {
      margin-left: 0px;
      width: 100%;
    }
  }

  #main-nav {
    margin: 0;
  }

  #main-nav {
    .nav-item {
      text-align: right;
      width: 90vw;
      margin-right: 10px;
      margin-left: 20px;
    }
  }

  .dropdown-item {
    a {
      img {
        width: 60px;
      }
    }
  }

  #login-dropdown {
    display: none;
  }

  .navbar-collapse {
    border: 0;
    width: 100%;
  }

  .login-item-nodrop {
    display: block;

    .login-image {
      height: 32px;
    }

    .login-lock-image {
      width: 15px;
      height: auto;
      margin-right: 10px;
      margin-top: -5px;
    }
  }

  .login-title {
    border-top: solid 1px $white-base;
    pointer-events: none;
    padding-top: 5px;
    padding-right: 20px;
  }

  .feature-text {
    margin-left: 50px;
    margin-top: 0px;
    font-size: 12px;
  }

  .feature-heading_img {
    width: 40px;
  }

  .feature-heading_text {
    margin-bottom: 12px;
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  #login-dropdown-button {
    background-color: $black;
    color: $white-base;
    right: 100px;
    font-family: OpenSans, Arial;

    i {
      margin-right: 15px;
    }
  }

  .open {
    > #login-dropdown-button {
      border-bottom: 1px solid white;
      border-radius: 0;
    }
  }

  .login-dropdown btn {
    border: none;
    border-radius: 0;
  }

  #login-dropdown-button {
    width: 96px;
    padding-left: 2px;
    padding-right: 20px;
    margin-top: -87px;
    height: 34px;
    padding-top: 7px;
    margin-right: 10%;
  }

  #login-dropdown-menu {
    margin-top: -57px;
    right: 100px;

    > .dropdown-item {
      background-color: $black;
      width: 96px;
      border-bottom: white solid 1px;
    }
  }

  .zmailLogo {
    float: left;
    height: 28px;
  }

  .dropdown-menu {
    background-color: black;
  }

  .dropdown-item {
    img {
      width: 50px;
    }

    a {
      width: 96px;
      background-color: $black;
    }
  }

  .dropdown-item:last-child {
    border-bottom: 0;
  }

  .login-dropdown {
    > .dropdown-menu {
      min-width: 96px;
      padding-top: 0px;
      right: 100px;
      padding-bottom: 0;
    }
  }

  .login-item-nodrop {
    display: none;
  }

  .nav-item {
    > .login-item-nodrop {
      display: none;

      .login-image {
        display: none;
      }

      a {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #loginNav {
    .nav-logo {
      margin-left: 0px;
      height: 30px;
    }
  }
}

//
// Home Section
//
#home {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 1000px;
  position: relative;
  width: 100%;

  .container {
    flex-grow: 1;
    margin: 0 0;
    color: $white-base;
  }

  .intro-heading_top {
    font-size: 40px;
    font-weight: lighter;
    margin-bottom: 0;
    line-height: 60px;
  }

  .intro-heading_bottom {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .landing-page-header {
    height: 50vh;
  }

  #home {
    .intro-text_summary {
      font-size: 10px;
    }

    .intro-heading_top {
      font-size: 14px;
      line-height: 10px;
    }

    .intro-heading_bottom {
      font-size: 23px;
      line-height: 20px;
    }
  }
}

//
// Product Section
//
#products {
  color: $black;

  .products-devices {
    &.full-size {
      width: 100%;
    }
  }
}

.product-header {
  color: $brand-primary;
  text-transform: uppercase;
}

.product-list {
  padding: 0;
  list-style-type: none;
}

.product-text {
  font-size: 18px;
  margin: 20px 0;
}

.product-list_item {
  vertical-align: top;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  background-size: 75px auto;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  #products {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .products-devices.full-size {
    display: none;
  }

  .mobile-size {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  h2 {
    font-size: 16px;
    font-weight: $extra-bold;
  }

  p {
    font-size: 12px;
  }

  .product-text {
    font-size: 12px;
    line-height: 20px;
  }

  .product-header {
    font-weight: $extra-bold;
    width: 65vw;
  }
}

@media screen and (min-width: 768px) {
  .products-devices.mobile-size {
    display: none;
  }
}

[data-img='zcruit'] {
  background-image: url('~Assets/images/LandingPage/zcruit-logo.png');
}

[data-img='zmail'] {
  background-image: url('~Assets/images/LandingPage/zmail-logo.png');
}

//
// Testimonials Section
//

#testimonials {
  color: $black;

  .signup-link {
    margin-left: 140px;
  }
}

.testimonials-text-container {
  vertical-align: center;
}

.testimonial-header {
  color: $brand-primary;
  margin-left: 7.5%;
  margin-top: 20px;
}

.testimonials-map {
  width: 100%;
}

.mobile-size {
  display: none;
}

.carousel {
  height: 150px;
  .carousel-inner {
    height: 150px;
    .carousel-item {
      height: 150px;
      .testimonial-item-inner {
        height: 150px;
        .testimonial-text {
          height: 60px;
        }
        .testimonial-name {
          height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #testimonials {
    padding-top: 10px;
  }

  .full-size {
    display: none;
  }

  .mobile-size {
    display: block;
  }
}

.testimonial-item-inner {
  width: 85%;
  margin: 0 auto;
}

.testimonial-name {
  font-family: Raleway-SemiBold;
}

.carousel-control.left {
  background: none;
  color: $gray;
  margin-top: 30px;
}

.carousel-control.right {
  background: none;
  color: $gray;
  margin-top: 30px;
}

.item {
  border-bottom: 0;
}

.carousel-indicators {
  display: none;
}

@media screen and (max-width: 767px) {
  #testimonials {
    .signup-link {
      margin-left: 30%;
    }
  }
}

//
// Press Section
//
#press {
  text-align: center;
  background-image: url('~Assets/images/LandingPage/grey-background.png');
  background-repeat: no-repeat;
  background-color: $gray-lighter;
  color: $gray;
  background-position: bottom right;

  @include background-cover;

  h2 {
    font-weight: 900;
    color: $black;
  }

  h3 {
    font-size: 18;
  }

  .line-filler svg path {
    stroke: $brand-primary;
  }

  .container {
    display: flex;
    align-items: center;
    align-content: space-around;
    justify-content: center;
    flex-wrap: wrap;

    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1em;
      height: 130px;
      width: 230px;
      background: white;
      radius: 2px;
      -webkit-box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);
      box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);
    }

    a img {
      height: 100px;
      transform: scale(0.6);
    }

    .sporttechie img {
      transform: scale(0.4);
    }

    .chicagotribune img {
      transform: scale(0.3);
    }
  }
}

//
// Team Section
//
#team {
  text-align: center;
  background: $white-base;
  color: $black;

  h2 {
    font-weight: 900;
    color: $black;
  }

  h3 {
    font-size: 18;
  }

  .line-filler svg path {
    stroke: $brand-primary;
  }

  .container {
    display: flex;
    align-items: center;
    align-content: space-around;
    justify-content: center;
    flex-wrap: wrap;

    .team-member {
      display: flex;
      align-content: space-around;
      align-items: center;
      justify-content: center;
      margin: 3em;
      height: 130px;
      width: 260px;
      background: white;
      radius: 2px;
      -webkit-box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);
      box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.17);

      .member-details {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        width: 170px;

        .member-name {
          font-weight: $extra-bold;
        }
      }
    }

    .team-member img {
      height: 100px;
      transform: scale(1.2);
      display: inline-block;
      margin-left: -50px;
    }
  }
}

//
// Contact Section
//

#contact {
  text-align: center;
  color: $black;
  background-image: url('~Assets/images/LandingPage/grey-background.png');
  background-repeat: no-repeat;
  background-color: $gray-lighter;
  background-position: bottom right;

  @include background-cover;

  h2 {
    font-weight: 900;
    color: $black;
  }

  h3 {
    font-size: 18;
  }

  Col {
    font-size: 26;
  }

  @media screen and (max-width: 767px) {
    Col {
      font-size: 15px;
    }
  }

  .line-filler svg path {
    stroke: $brand-primary;
  }

  .container {
    display: flex;
    align-items: center;
    align-content: space-around;
    justify-content: center;
    margin: 2em;
  }
}

button:focus {
  outline-offset: 3px;
}
//
// Connect Section
//
#connect {
  padding-bottom: 145px;
  text-align: center;
  color: white;
  background-image: url('~Assets/images/LandingPage/footer-background.png');
  background-repeat: no-repeat;

  @include background-cover;

  .line-filler svg g {
    fill: darken($brand-primary, 10%);
    stroke: darken($brand-primary, 10%);
  }

  .icon-twitter,
  .icon-linkedin,
  .icon-facebook {
    background-color: transparent;
  }

  h2 {
    font-weight: 900;
  }

  .container a .icon {
    color: $white-base;
    stroke: currentColor;
    fill: currentColor;
    display: inline-block;
    width: 10em;
    height: 10em;
    margin: 0 1vw 0 1vw;
    stroke-width: 0;
    cursor: pointer;
    transform: scale(0.5);
    border: 5px solid white;
    padding: 25px;

    @media screen and (max-width: 767px) {
      width: 8em;
      height: 8em;
      margin: 0;
    }
  }

  .container a .icon-twitter {
    color: white;
  }
}

//
// Footer Section
//
footer {
  color: $gray;
  font-size: 11px;
  padding: 0;
  text-align: center;
  background-color: rgba(32, 32, 32, 0.7);
  margin-top: -110px;

  a {
    cursor: pointer;
  }

  a:hover {
    // text-decoration: none
    color: white;
  }

  .copy {
    padding-top: 10px;
  }

  .privacy {
    .col-md-4 {
      display: flex;
      justify-content: space-between;
    }
  }

  .container {
    .row {
      .col {
        a {
          .icon {
            color: $gray;
            stroke: currentColor;
            fill: currentColor;
            display: inline-block;
            width: 1.8em;
            height: 1.8em;
            margin-right: 1em;
            stroke-width: 0;
            cursor: pointer;
          }

          .icon-twitter {
            color: $brand-primary;
          }
        }
      }
    }
  }

  .zcruit-footer-text {
    margin-top: 10px;
    color: white;
    font-size: $twelve;
  }

  .zcruit-footer-policy-links {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: inline-flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      // height: 14px;
      margin-left: 10px;
    }
    div:not(:last-child) {
      border-width: 0 1px 0 0;
      border-color: white;
      border-style: solid;

      > a {
        margin-right: 3px;
      }
    }
    div:not(:first-child) {
      > a {
        margin-left: 4px;
      }
    }
    a {
      color: white;
      font-size: $twelve;
    }
  }
}

@import '~Styles/general/_variables';

.navigation-search {
  height: 40px;
  padding: 0;
  position: relative;

  .search-area {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: flex-start;
    transition: width 500ms ease-in;
    width: auto;

    .search-input-container {
      width: 0;
      transition: width 500ms ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &.expanded {
        width: 260px;
      }

      label {
        font-weight: unset;
        margin-bottom: 0;
      }
    }

    input {
      border: none;
      outline: none;
      padding: 7px 15px;
      width: 260px;
    }

    .click {
      align-items: center;
      display: flex;
      height: inherit;
      padding: 0 20px;

      .search {
        color: $black-dark;
      }
    }
  }

  .search-results {
    background: $white-base;
    position: absolute;
    top: 52px;
    width: 100%;

    h3 {
      background: $grey-base;
      color: $grey-dark;
      padding: 7px 30px;
      font-size: $thirteen;
    }

    .result {
      a,
      .a-replacement {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;

        p {
          font-size: $thirteen;
          width: 50%;

          &.location {
            font-style: italic;
          }

          .bold {
            font-weight: 900;
          }
        }
      }

      .alert {
        margin: 15px 30px;
        padding: 7px 15px;
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.talent {
                .col-md-12 {
                  border-bottom: 1px solid $white-light;
                  padding: 0 0 7px;

                  h3 {
                    font-size: $forty;
                    font-weight: 700;
                    text-align: center;
                  }
                }

                .talent_holder {
                  .row {
                    .col-md-4 {
                      align-items: center;
                      display: flex;
                      flex-direction: column;
                      flex-grow: 0;
                      flex-shrink: 0;
                      font-size: $sixteen;
                      justify-content: center;
                      padding-top: 15px;

                      .icon-holder {
                        margin-bottom: 7px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.error-page {
              .box-holder {
                .box {
                  display: flex;
                  padding: 0;

                  .error {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 50px 0 100px;
                    width: 100%;

                    h2 {
                      text-align: center;
                      width: 100%;
                    }

                    .icon-holder {
                      background-color: rgba(117, 70, 129, 0.2);
                      border: 0;
                      flex-shrink: 0;
                      flex-grow: 0;
                      height: 100px;
                      margin-bottom: 45px;
                      width: 100px;
                      text-align: center;

                      .school {
                        background-color: $purple-base;
                        height: 57px;
                        width: 50px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.tag {
  background-color: $white;
  border: 1px solid $white;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;

  &:focus-within {
    background-color: $white;
    border-color: $light-grey;
  }
}

.tag-remove {
  color: $white;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

@import '~Styles/general/_variables';

.filter {
  &.years {
    .collapse,
    .collapsing {
      .btn {
        color: #ffffff;
        display: block;
        font-size: 0.75rem;
        padding: 7px 4px;
        text-align: center;
      }
    }
  }
}

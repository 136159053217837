@import '~Styles/general/_variables';

.video-details {
  .video-details-row {
    align-items: center;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    padding: $eight $sixteen;

    &.video-details-upper {
      padding-bottom: 0;

      .video-details-player {
        font-size: $twenty;
        font-weight: 600;
        margin: 0;
      }
    }

    &.video-details-lower {
      .video-location-date-container {
        display: flex;
        justify-content: space-between;

        .icon-info {
          margin-right: 40px;

          .icon-holder {
            height: $forty;
            width: $forty;
            display: inline-flex;

            .icon {
              text-align: center;
              font-size: $sixteen;
              height: $sixteen;
              width: $sixteen;
            }
          }
        }
      }

      .video-link-container {
        .btn {
          border: 1px solid $grey;
          color: $grey;
          padding: $eight $thirty-two;
          margin-left: $sixteen;

          &:hover {
            background-color: $grey-base;
          }
        }

        .hidden-share-link {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.utility-bar {
  background-color: $black-dark;
  color: $grey-light;
  display: flex;
  min-height: 62px;
  margin: 0;
  position: relative;
  z-index: 2;

  .col-md-8,
  .col-md-10,
  .col-md-12 {
    //align-items: center;
    display: flex;
    //justify-content: center;
    padding: 0 50px;
  }

  .dropdown-filter {
    .dropdown-menu {
      max-height: 300px;
      overflow: scroll;
    }

    .btn {
      background-color: $black-light;
      display: inline-block;
      color: $white;
      margin-top: 0;
      padding: 10px 25px 10px 16px;
      border-radius: 3px;
      font-weight: 100;
      i {
        margin-left: 15px;
      }
    }
  }
}

.page-header {
  background-image: linear-gradient(45deg, #46134e 0%, #754681 92%);
  height: 120px;
  margin: 0;
  overflow: hidden;
  position: relative;

  &:after {
    -webkit-clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);

    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 33%;
    z-index: 1;
  }

  .col-md-10,
  .col-md-12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    .page-header-text {
      color: $white-base;
      font-size: 42px;
      font-weight: 900;
      margin: 0;
      padding: 0;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;

      .downloader {
        align-items: center;
        color: $white-base;
        display: flex;
        justify-content: center;

        .downloadWithBox {
          height: 14px;
          margin-right: 5px;
          width: 16px;
        }
      }
    }
  }
}

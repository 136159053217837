@import '~Styles/general/_variables';

.twitter-filters {
  display: flex;

  .twitter-filters-filter {
    .dropdown-filter {
      .btn {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        background: none;
        border: none;
        border-right: 1px solid $grey-light;
        color: $black-base;
        padding: 7px 30px;

        &:active {
          background: none;
          border: none;
          border-right: 1px solid $grey-light;
          color: $black-base;
        }

        .icon {
          color: $black-dark;
        }
      }
    }

    &:last-of-type {
      .dropdown-filter {
        .btn {
          border: none;
        }
      }
    }
  }
}

.box-holder {
  justify-content: space-between;
  // https://stackoverflow.com/questions/8840580/force-dom-redraw-refresh-on-chrome-mac
  transform: translateZ(0);

  .box {
    background-color: $white-base;
    -webkit-box-shadow: 0 0 11px 0 rgba(204, 204, 204, 0.5);
    -moz-box-shadow: 0 0 11px 0 rgba(204, 204, 204, 0.5);
    box-shadow: 0 0 11px 0 rgba(204, 204, 204, 0.5);
    margin-bottom: 15px;
    padding: 30px 45px;
  }
}

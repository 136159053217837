@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.table-row {
  width: 100%;
}

.table-icon-container {
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
  border-top: none !important;

  .zcruit-logo {
    width: 50px;
    img {
      height: 25px;
    }
  }

  .editor,
  .deleter {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 25px;
    justify-content: center;
    width: 25px;
    outline: none;
    background-color: transparent;

    &:focus {
      @include outline();
    }
  }

  .editor {
    margin-right: 7px;
    border: 1px solid $green-base;
    height: 25px;
    width: 25px;

    .edit {
      text-align: center;
      color: $green-base;
      font-size: $ten;
      height: $ten;
      width: $ten;
    }
  }

  .deleter {
    border: 1px solid $red-base;

    .x {
      text-align: center;
      color: $red-base;
      font-size: $twelve;
      height: $twelve;
      width: $twelve;
    }
  }

  .toggler {
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:focus {
      @include outline();
    }
  }

  .icon {
    // swap out for edit icon
    &.edit {
      color: green;
    }

    &.x {
      color: red;
    }

    &.caret-down {
      color: #9b9b9b;
      font-size: $twelve;
      height: $twelve;
      width: $twelve;
      margin-top: 15%;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}

.filter-details-row {
  overflow-x: hidden;
  max-width: 1200px;

  .filter-details-container {
    padding-top: 20px;
    padding-bottom: 20px;

    .filter-details {
      display: inline-block;
      margin: 8px 40px;
    }
  }
}

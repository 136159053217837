@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';
@import '~Styles/functions/_svg-uri';

.filter {
  .nullable {
    margin-bottom: 15px;
    position: relative;

    &:focus-within {
      @include outline();
    }

    label {
      color: $grey-dark;
      font-size: $thirteen;
      font-weight: normal;
    }
  }
}

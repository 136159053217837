body {
  .tooltip {
    .twitter {
      width: 500px;
      border-bottom: 1px solid $grey-dark;

      .twitter-header {
        background-color: $black-darkest;
        padding: 10px 15px;

        h2 {
          align-items: center;
          display: flex;
          font-size: $twelve;
          justify-content: center;
          margin: 0;

          i {
            margin-right: 7px;
          }
        }
      }

      .twitter-body {
        padding: 10px 20px 20px;

        .person {
          align-items: center;
          display: flex;
          justify-content: center;
          margin-bottom: 15px;

          .image {
            -webkit-clip-path: circle(42% at 50% 50%);
            clip-path: circle(42% at 50% 50%);

            align-self: baseline;
            margin-right: 7px;
            max-width: 75px;
            width: auto;
          }

          .info {
            width: 80%;

            .name {
              font-size: $thirteen;
              font-weight: $bold;
            }
          }
        }

        .interaction {
          .tweet {
            margin-bottom: 7px;
          }

          .date {
            color: $grey-dark;
          }
        }
      }
    }
  }
}

.page-content {
  background-color: $white-light;

  .col-md-8 {
    padding: 50px 50px 0;
  }

  .dropdown-menu-show {
    left: auto;
    padding-left: 1px;
    padding-right: 1px;
    right: 0;
  }
}

@import '~Styles/general/_variables';

.date-picker {
  display: flex;
  background-color: $black-dark;
  color: white;
  font-weight: 700;
  font-family: Chivo, Arial, Helvetica, sans-serif;

  .date-picker__input {
    display: flex;
    flex-direction: column;

    .date-picker__input-label {
      margin-bottom: 5px;
    }

    .DayPickerInput {
      input {
        background-color: $black-dark;
        border: 1px solid $black-light;
        color: $grey-light;
        height: 30px;
        padding-left: 20px;
      }
    }
  }
}

/* DayPicker styles */
/* Modified from source - https://github.com/gpbl/react-day-picker/edit/master/src/style.css */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
  user-select: none;
}

.DayPicker-NavBar {
  position: absolute;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 8%;
}

.DayPicker-NavButton {
  top: 1em;
  right: 1.5em;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.DayPicker-NavButton--prev {
  transform: rotate(90deg);

  .icon.caret-down {
    font-size: $fourteen;
    height: $fourteen;
    width: $fourteen;
    color: white;
  }
}

.DayPicker-NavButton--next {
  transform: rotate(-90deg);

  .icon.caret-down {
    font-size: $fourteen;
    height: $fourteen;
    width: $fourteen;
    color: white;
  }
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center;
  > div {
    font-weight: 500;
    font-size: 1.15em;
  }
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
  abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
  border-spacing: 10px;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--selected.DayPicker-Day {
  border-radius: 0 !important;
}
.DayPicker-Day--selected.DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.DayPicker-Day--selected.DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
  &:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: $purple-base;
  color: #f0f8ff;
  &:hover {
    background-color: $purple-base-transparent;
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: $black-dark;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

@import '~Styles/general/_variables';

.activity {
  &__date-group {
    margin-bottom: 45px;

    &__body {
      display: flex;
      padding: 7px 15px;

      &--grey {
        background-color: $white-lightest;
        display: flex;
        padding: 7px 15px;
      }

      &__image {
        border-radius: 50%;
        display: flex;
        flex: 0 0 50px;
        margin-right: 7px;
        position: relative;
        width: 50px;

        img {
          clip-path: circle(42% at 50% 50%);
          align-self: baseline;
          max-height: 67px;
          width: 100%;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-size: $fourteen;
          line-height: $sixteen;
          margin: 0;

          a {
            font-size: $fourteen;
          }
        }

        p {
          font-size: $twelve;
          line-height: $fourteen;
          margin: 0;
          padding: 0;
        }
      }
    }

    &__header {
      display: block;

      h2 {
        border-bottom: 1px solid $white-light;
        font-size: $sixteen;
        position: relative;
        text-align: center;

        span {
          background-color: $white-base;
          padding: 0 20px;
          position: relative;
          top: 10px;
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              .stat_holder {
                .stat_holder_stat {
                  border-bottom: 1px solid $grey-base;
                  padding-top: 15px;

                  &:first-of-type {
                    padding: 0;
                  }

                  &:last-of-type {
                    border: none;
                  }

                  .stat_holder_stat_stat {
                    align-items: center;
                    display: flex;
                    cursor: pointer;

                    .info {
                      display: flex;
                      flex-wrap: wrap;
                      width: 100%;

                      h4 {
                        font-size: $thirteen;
                        margin: 0;
                        width: 100%;
                      }

                      .bottom {
                        align-items: baseline;
                        display: flex;
                        width: 100%;

                        p {
                          font-size: $twenty-four;
                          font-weight: 700;
                          line-height: 1.5rem;
                        }

                        .bottom_icons {
                          display: flex;
                          align-items: center;

                          .dash-circle {
                            margin: 0 7px;
                          }

                          .icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: $sixteen;
                          }

                          .verified {
                            margin: 0 7px;
                            color: $green-base;
                          }

                          .star-holder {
                            border-left: 1px solid $grey-base;
                            padding-left: 7px;
                            margin-right: 7px;

                            .star {
                              margin: 0;
                            }
                          }
                        }
                      }

                      .rivals_camp,
                      .rivals_combine {
                        background-color: transparent;
                        margin-left: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.filter {
  .navigation-search {
    height: auto;
    padding: 0;
    position: relative;

    .search-area {
      align-items: center;
      display: flex;
      height: inherit;
      justify-content: flex-start;
      overflow: visible;
      transition: width 500ms ease-in;
      width: auto;

      input {
        border: none;
        padding: 0;
        transition: width 500ms ease-out;
        width: 0;

        &.expanded {
          outline: none;
          padding: 7px 15px;
          transition: width 500ms ease-out;
          width: 100%;

          &:focus {
            @include outline();
          }
        }
      }

      .click {
        align-items: center;
        display: flex;
        height: inherit;
        padding: 0 20px;

        .search {
          color: $black-dark;
        }
      }
    }

    .search-results {
      background: $black-light;
      max-height: 300px;
      overflow: scroll;
      position: absolute;
      top: 38px;
      width: 100%;
      z-index: 100;

      .result {
        padding: 7px 15px 7px 20px;
        position: relative;

        &.selected {
          background: $grey-dark;
          color: $white-base;

          .name {
            .icon {
              background-color: $purple-base;
              color: $white;
              font-size: $twelve;
              width: $twelve;
              height: $twelve;
              left: 5px;
              position: absolute;
              top: 11px;
            }
          }
        }

        .name {
          font-size: $twelve;
          margin: 0;
          padding: 0;
        }

        .alert {
          margin: 15px 30px;
          padding: 7px 15px;
        }
      }
    }

    .selected-results {
      display: flex;
      flex-wrap: wrap;
      margin-top: 7px;

      .pill {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        align-items: center;
        background-color: $purple-base;
        border: none;
        display: flex;
        justify-content: space-between;
        margin: 3px;
        padding: 3px 4px;

        p {
          color: $white-base;
          font-size: $twelve;
          margin: 0 7px 0 0;
        }
      }
    }
  }
}

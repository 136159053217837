@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.filters {
  &.filters-overlay {
    overflow-y: scroll;
    padding-top: 45px;
    padding-bottom: 12vh;
    position: absolute;
    top: 0;
    z-index: 2;

    &.opening {
      transform: translateX(0%);
      transition: transform 500ms;
    }

    &.closed {
      transform: translateX(-105%); // adding extra as there is a box-shadow
      transition: transform 500ms;
    }

    .btn {
      &.type-selector {
        color: $white-base;
        font-size: $fourteen;
        justify-content: center;
        letter-spacing: initial;
        margin-bottom: 7px;
        padding: 10px 0;
        width: 100%;

        &:last-of-type {
          margin-bottom: 30px;
        }
        &:focus {
          @include outline();
        }
      }
    }

    .button-filters {
      .save-button {
        background-color: $grey-darkest;
        &:disabled {
          background-color: $grey-advanced-filters;
          color: $grey-base;
        }
      }
      .row {
        .filter-functions-container {
          margin-bottom: 15px;
          text-align: center;

          a {
            color: $white-base;
          }
        }
      }
    }

    .close-modal-container {
      position: absolute;
      right: 30px;
      top: 15px;

      .close-modal {
        background-color: transparent;
        border: none;
        .icon-holder {
          background-color: transparent;
          border: 2px solid $grey-dark;

          .x {
            text-align: center;
            color: $grey-dark;
            font-size: $ten;
            height: $ten;
            width: $ten;
          }
        }
      }
    }

    .label-header {
      align-items: center;
      color: $grey-dark;
      display: flex;
      font-size: 0.75rem;
      font-weight: 300;
      justify-content: space-between;
      padding: 0;
      text-transform: uppercase;
      width: 100%;
    }
  }
}

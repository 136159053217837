@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-wrapper {
          .modal-body {
            .secondary-page {
              &.contact-page {
                max-height: 250px;

                .box-holder {
                  position: relative;
                  top: 0;
                }

                .collapse,
                .collapsing {
                  .table {
                    thead {
                      background: $black-base;
                      color: $white-base;
                    }

                    tbody {
                      tr {
                        border: none;

                        td {
                          p {
                            margin-bottom: 7px;

                            &:last-of-type {
                              margin: 0;
                            }
                          }
                        }
                      }

                      tr:nth-of-type(odd) {
                        background-color: $white-base;
                      }

                      tr:nth-of-type(even) {
                        background-color: $white-light;
                      }
                    }
                  }
                }

                .contact-body {
                  cursor: pointer;

                  .main {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;

                    a {
                      font-size: 0;
                      margin-left: 5px;
                    }

                    .age {
                      font-size: 13px;
                      white-space: pre;
                      margin-top: 2px;
                    }

                    .info {
                      align-items: center;
                      display: flex;
                      font-weight: bold;
                      width: 23%;

                      div {
                        font-size: 1rem;
                        font-weight: bold;
                      }

                      &.address {
                        width: 20%;
                      }

                      &.general-info {
                        width: 100%;
                      }

                      &.basic {
                        flex-wrap: wrap;
                        justify-content: initial;
                        text-transform: capitalize;

                        p {
                          font-weight: normal;
                          margin: 0;
                        }
                      }

                      &.icon {
                        background: none;
                        width: 50px;

                        .icon-holder {
                          height: 25px;
                          width: 25px;
                        }

                        .caret-down {
                          -webkit-transition: transform 350ms;
                          -moz-transition: transform 350ms;
                          -ms-transition: transform 350ms;
                          -o-transition: transform 350ms;
                          transition: transform 350ms;

                          height: 16px;
                          transform: rotate(0deg);

                          &.flip {
                            -webkit-transition: transform 350ms;
                            -moz-transition: transform 350ms;
                            -ms-transition: transform 350ms;
                            -o-transition: transform 350ms;
                            transition: transform 350ms;

                            transform: rotate(-180deg);
                          }
                        }
                      }

                      &.phone {
                        .values {
                          p {
                            span {
                              font-weight: normal;
                            }
                          }
                        }
                      }

                      .icon-holder {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        height: 40px;
                        margin-right: 0.5rem;
                        width: 40px;
                        i {
                          font-size: $sixteen;
                          height: $sixteen;
                          width: $sixteen;
                        }
                      }

                      .values {
                        width: 100%;
                        word-break: break-word;

                        p {
                          margin: 0;
                        }
                      }
                    }
                    .social {
                      display: flex;
                      align-items: center;
                      a {
                        width: $sixteen;
                        height: $sixteen;
                        display: inline-block;

                        i.icon {
                          font-size: $sixteen;
                          width: $sixteen;
                          height: $sixteen;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .twitter-insights {
            .page-title-container {
              display: flex;
              align-items: flex-start;
              margin-bottom: 20px;

              .page-title {
                color: $white;
                font-size: 1.9rem;

                .page-title-inner {
                  margin-right: 10px;
                }

                .page-title-handle {
                  color: $white;
                  font-size: 1.5rem;
                  padding-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.rivals-camp {
  > .page-content {
    min-height: calc(100% - 65px);

    .col-md-8 {
      padding: 50px 40px 0;
    }
  }

  .utility-bar .dropdown-filter .btn {
    background-image: none;
    padding: 10px 16px 10px 16px;

    .icon {
      margin-left: 24px;
    }
  }
}

@import '~Styles/general/_variables';

.activity {
  .with-sidebar {
    background: $white-base;
    padding: 50px;
  }

  .no-padding {
    padding: 0;
  }

  .actions {
    flex-wrap: wrap;
    max-width: 200px;

    .exporter {
      .btn {
        padding: 7px 0;
      }
    }
  }

  .button-center {
    text-align: center;
  }

  .filters {
    height: 100vh;
    padding-bottom: 12vh;

    .filter-group {
      border-bottom: 1px solid $grey-darkest;
      margin-bottom: 30px;

      &:nth-last-of-type(2) {
        border: none;
      }

      h2 {
        color: $white-base;
        font-size: $twenty;
        font-weight: $bold;
        line-height: $twenty;
        margin: 0 15px 20px;
      }
    }

    .button-filter-col {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 12vh;
      display: flex;
      justify-content: center;
      background-color: $black-dark;

      .button-filters {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        background-color: $black-dark;
        width: 100%;
        border-top: 1px solid $grey-darkest;
        margin-top: 1px;
        padding-top: 2vh;

        .btn {
          width: 100%;
        }
      }
    }
  }
}

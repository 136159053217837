@import '~Styles/general/_variables';

.manage-users {
  .row {
    align-items: flex-start;

    .account-box {
      border: 1px solid $light-grey;
      -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
      background-color: $white;
      padding: 2rem;

      &.users-table {
        margin-bottom: 1%;
      }

      &:not(:first-child) {
        margin-left: 2rem;
      }

      .title {
        width: 70%;
        font-size: 1.16rem;
        font-weight: 800;
        padding-bottom: 0.5rem;
      }

      .account-box__content {
        td {
          font-size: 0.875rem;
          vertical-align: inherit;

          .table-icon-container {
            display: flex;
            justify-content: center;
            .deleter {
              border: 1px solid red;
              background-color: transparent;
            }

            .icon {
              &.x {
                color: red;
                width: 11px;
                height: 11px;
              }

              &.edit {
                color: green;
              }
            }
          }
        }

        option {
          &:disabled {
            font-style: italic;
            color: $grey;
          }
        }

        .form-group {
          label {
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        .account-box__text {
          color: $grey;
          font-size: 0.875rem;
          font-weight: 400;
        }

        .account-box__text--bold {
          font-weight: 700;
        }

        .account-box__submit {
          background-color: $purple;
          margin-top: 20px;
        }
      }
    }
  }
}

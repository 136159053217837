@import '~Styles/general/_variables';

.info-tooltip-content {
  max-width: 245px;
  color: $grey-dark;
  font-size: $ten;
  text-align: left;

  a {
    text-decoration: underline;
    color: $grey-dark;
    font-size: $ten;
  }
}

.info-tooltip-icon {
  width: $sixteen;
  height: $sixteen;
  background: $grey-base;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: $four;

  i {
    color: $grey-icon;
    font-size: $eight;
  }
}

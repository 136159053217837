@import '~Styles/general/_variables';

.account-box {
  &.col-md-8 {
    border: 1px solid $light-grey;
    -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    padding: 2rem;

    .title {
      width: 70%;
      font-size: 1.16rem;
      font-weight: 800;
      padding-bottom: 0.5rem;
    }

    .account-box__content {
      .account-box__text {
        color: $grey;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .account-box__text--bold {
        font-weight: 700;
      }

      .account-box__input-label {
        font-weight: 600;
        margin-bottom: 0;
        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      .account-box__submit {
        background-color: $purple;
        margin-top: 20px;
      }
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.box-holder {
  .no-padding {
    padding: 0;
  }

  .box {
    &.no-top-padding {
      padding-top: 0;
    }
  }
}

.carousel-rankings {
  display: flex;
  flex-direction: row;

  .carousel-rankings-nav {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: none;
    width: 30px;
    background-color: transparent;
    border: none;
    box-shadow: 4px 4px 6px 0px rgba(157, 152, 152, 0.4);
    border-radius: 4px;
    z-index: 1;

    &:focus {
      @include outline();
    }

    .previous-arrow,
    .next-arrow {
      color: $black-base;
      font-size: $sixteen;
      width: $sixteen;
      height: $sixteen;
    }

    &:hover {
      .previous-arrow,
      .next-arrow {
        color: $black-dark;
      }
    }
  }

  &.other-schools {
    .col-md-4 {
      padding: 0;
    }
  }
}

.carousel-rankings-content {
  justify-content: space-between;
  width: 100%;
}

.other-schools {
  .carousel-rankings-content {
    justify-content: space-around;
  }
}

.carousel-rankings-content,
.my-school {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: auto;

  .carousel-rankings-item {
    cursor: pointer;
    text-align: center;

    .school-image {
      height: 65px;
      width: 65px;
      padding: 13px;
      margin: 10px auto;

      &.highlighted {
        -webkit-box-shadow: 0 2px 8px 1px rgba(103, 169, 31, 1);
        -moz-box-shadow: 0 2px 8px 1px rgba(103, 169, 31, 1);
        box-shadow: 0 2px 8px 1px rgba(103, 169, 31, 1);
      }

      img {
        width: 40px;
      }
    }

    .rankings-icon-total-container {
      align-items: center;
      background-color: $black-darkest;
      position: absolute;
      border-radius: 1.5rem;
      border: 1px solid $black-darkest;
      display: flex;
      height: 1.5rem;
      justify-content: center;
      right: 2rem;
      top: 3.6rem;
      width: 1.5rem;

      &.preview {
        right: 1rem;
      }

      .rankings-icon-total {
        font-size: 0.7rem;
        color: $white;
      }
    }

    .rankings-icon-name {
      font-size: $thirteen;
      font-weight: $bold;
    }

    &.col-md-4 {
      flex: 0;
      padding: 0 3px;

      &:last-of-type {
        padding-right: 15px;

        .rankings-icon-total-container {
          right: 15px;
        }
      }
    }
  }
}

.my-school {
  border-left: 1px solid $white-med;
  padding-right: 0 !important;
  overflow: visible;

  .carousel-rankings-item {
    min-width: 100px;
    padding: 0;
    word-break: break-all;

    .rankings-icon-total-container {
      right: 12px;
    }
  }
}

.box-holder {
  .power-rankings-container {
    .box {
      &#box-dark {
        background-color: $black-medium;
        padding-top: 0;
        color: white;
        text-align: center;

        .power-details-header {
          background-color: $black-dark;
          margin: 0 -45px;
          padding: 10px 30px;

          h3 {
            font-weight: normal;
            font-size: 1.5rem;

            .compare {
              margin: 0 15px;
            }
          }
        }

        .power-details-content {
          .power-details-likes {
            margin-top: 15px;

            .interaction-type-summary {
              .container {
                .row {
                  > svg {
                    margin-left: auto;
                  }
                }
              }

              .total-likes-container {
                align-items: center;
                display: flex;
                justify-content: center;

                p {
                  align-items: center;
                  display: flex;
                  font-size: 0.9rem;
                  margin: 0;

                  .total-likes {
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin-right: 7px;
                  }
                }
              }

              .icon-block-heart {
                background-color: $black-light;

                .icon {
                  font-size: $twenty-eight;
                  width: 30px;
                  height: 30px;
                  margin: 15px 0;
                }
              }
            }

            .account-breakdown {
              align-items: center;
              display: flex;

              .account-type {
                border-right: 1px solid $black-light;
                width: 25%;

                h4 {
                  line-height: $eighteen;
                  margin: 0 0 3px;
                }

                p {
                  margin: 0;
                  padding: 0;
                }
              }

              &:last-child {
                border-right: none;
              }
            }
          }

          .power-details-spread-row-container {
            margin: 20px 0;

            .power-details-spread-type {
              font-size: 1rem;
              text-align: left;
            }

            .power-details-row {
              .power-details-role {
                .power-details-role-summary {
                  background-color: $black-light;

                  .trigger {
                    background-color: $black-dark;
                    bottom: 0;
                    color: $white-dark;
                    cursor: pointer;
                    line-height: $twenty;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    width: 20px;
                  }

                  .power-details-role-text {
                    padding: 15px 0;

                    p {
                      font-weight: 100;
                      margin-bottom: 0;
                    }

                    h5 {
                      font-weight: bold;
                    }
                  }
                }

                .power-details-role-list {
                  background-color: $black-light;
                  border-top: 1px solid $black-dark;

                  > p {
                    margin: 0.5rem;
                    text-align: left;
                    text-transform: capitalize;
                  }

                  &:last-child {
                    padding-bottom: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-holder {
  .box {
    &.no-top-padding {
      padding-top: 0;
    }
  }
}

.box-holder {
  .box {
    .card-header {
      align-items: center;
      background: none;
      display: flex;
      justify-content: space-between;
      padding-right: 45px;
      padding-left: 45px;

      h2 {
        margin: 7px 0;
      }
    }

    .timeline-container {
      padding-left: 45px;
      padding-right: 45px;

      .timeline {
        .header {
          h3 {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.box-holder {
  .box {
    .like-trends-container {
      .chart-container {
        margin-top: 40px;

        .like-trends-chart-tooltip {
          min-width: 100px;
          position: absolute;
          z-index: 1100;
          color: white;
          pointer-events: none;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #373c43;
            position: absolute;
            top: 100px;
            left: 40px;
          }

          .like-trends-chart-tooltip-inner {
            background-color: #373c43;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100px;

            .school-image {
              margin: 10px 0;
            }

            .tooltip-school-text {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }

      .list-wrap {
        height: 380px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: $white-light;

        .list-item {
          background-color: $white-light;
          border-bottom: $white-med 1px solid;

          .list-item-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .list-item-text {
              font-size: 0.8125rem;
              margin-bottom: 0;
            }
          }

          .list-item-tweet-num-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .list-item-percentage {
              font-weight: bold;
              font-size: 1rem;
              margin-bottom: 0;
            }

            .list-item-total {
              font-size: 0.5rem;
              margin-bottom: 0;
            }
          }

          .school-image {
            height: 40px;
            width: 40px;
            padding: 10px;
            margin: 10px auto;

            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.box-holder {
  .box {
    .twitter-stream-container {
      display: flex;
      overflow: scroll;
      margin: 0 45px;
      padding: 15px 0;

      .single-tweet {
        align-self: stretch;
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        margin: 0.5rem;
        width: 25%;

        &:first-of-type {
          margin-left: 7px;
        }

        &:last-of-type {
          padding-right: 7px;
        }

        .tweet {
          box-shadow: 0 0 10px 1px $white-med;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;

          .tweet-body {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            padding: 0 15px;

            p {
              margin-bottom: 10px;

              &:last-of-type {
                font-size: 12px;
                font-style: italic;
                margin-bottom: 0;
              }
            }
          }

          .tweet-header {
            background-color: $white-light;
            font-size: $twelve;
            padding: 7px;
            text-align: center;

            .icon {
              text-align: center;
              font-size: $twelve;
              height: $twelve;
              width: $twelve;
              margin-right: 4px;
            }
          }

          .twitter-likes {
            align-items: center;
            display: flex;
            margin-top: auto;
            padding: 0 15px 15px;

            span {
              font-size: $twelve;
            }

            .like {
              height: 20px;
              margin-right: 4px;
              width: 24px;
            }
          }

          .twitter-user {
            display: flex;
            margin-bottom: 15px;
            padding: 15px 15px 0;

            .image {
              margin-right: 7px;

              .twitter-profile-picture {
                border-radius: 50%;
              }
            }

            .name {
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.icon-legend {
  display: flex;
  justify-content: center;
  border-top: 1px solid $grey-light;
  padding: 15px 0;
  font-size: $twelve;

  .single {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 15px;

    &.legend {
      font-weight: bold;
      margin-right: 45px;
    }

    .icon {
      text-align: center;
      margin-right: 7px;
      font-size: $twelve;
      height: $twelve;
      width: $twelve;
    }
  }
}

.twitter {
  width: 500px;
  border-bottom: 1px solid $grey-dark;

  .twitter-header {
    background-color: $black-darkest;
    padding: 10px 15px;

    h2 {
      align-items: center;
      display: flex;
      font-size: $twelve;
      justify-content: center;
      margin: 0;

      i {
        margin-right: 7px;
      }
    }
  }

  .twitter-body {
    padding: 10px 20px 20px;

    .person {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .image {
        -webkit-clip-path: circle(42% at 50% 50%);
        clip-path: circle(42% at 50% 50%);

        align-self: baseline;
        margin-right: 7px;
        max-width: 75px;
        width: auto;
      }

      .info {
        width: 80%;

        .name {
          font-size: $thirteen;
          font-weight: $bold;
        }
      }
    }

    .interaction {
      .tweet {
        margin-bottom: 7px;
      }

      .date {
        color: $grey-dark;
      }
    }
  }
}

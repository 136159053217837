@import '~Styles/general/_variables';

.video-multiplayer-list {
  .jersey-filter-input {
    margin: 3rem auto;

    .input {
      background-color: $white;
      border: transparent;

      input {
        background-color: $white;
        color: $grey-dark;
        border: none;
      }

      icon {
        background-color: $grey-dark;
      }
    }
  }
}

@import '~Styles/general/_variables';

.login-page {
  .col-md-6 {
    &:first-of-type {
      background-image: url('~Assets/images/hero.jpg');
      background-size: cover;

      .overlay {
        background: $purple-gradient-dark;
        height: 100vh;
        opacity: 0.83;
        overflow: hidden;
        padding: 75px;

        p {
          color: $white-base;
          font-size: 4rem;
          line-height: 4.25rem;
        }

        p:first-of-type {
          color: $white-base;
          font-size: 2.25rem;
          line-height: 3rem;
          margin-top: 50%;
        }
      }
    }

    &.right {
      margin-top: 20%;
      padding: 0 75px;

      h3 {
        margin-bottom: $thirty-two;
      }

      form {
        > .form-group {
          margin-bottom: $sixteen;

          &:last-of-type {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: $thirty-two;
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal-dialog.opt-in-modal {
  width: 500px !important;

  .modal-content {
    padding: 33px 28px 34px 25px;
    box-shadow: 0px 0px 11px rgba(204, 204, 204, 0.5);

    .modal-header {
      margin: 0;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      margin-bottom: 0;

      .modal-heading {
        color: $black;
        height: 24px;
        font-size: $twenty;
        line-height: $twenty-four;
        margin: 0px auto 47px auto;
        font-weight: $extra-bold;
      }

      .modal-text {
        color: #333333;
        p {
          font-size: $sixteen;
          line-height: $twenty;
          margin-bottom: 0px;

          a {
            color: $purple;
          }
        }

        p:first-child {
          height: 87px;
          margin-bottom: 5px;
        }

        p.notice-of-agreement {
          font-size: $twelve;
          line-height: $fourteen;
          font-style: italic;
          font-weight: $regular;
          height: 40px;
          margin-bottom: 43px;
        }
      }

      .continue-button {
        height: 42px;
        margin: 0 auto;
        color: white;
        background-color: $purple;
      }
    }
  }
}

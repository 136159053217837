@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .basic-info-row {
            margin: 0 -40px;
            position: relative;
            z-index: 2;

            .basic-info {
              background: $purple-gradient-dark;
              color: $white-base;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              padding: 0 0 0 40px;
              transform: skewX(-10deg);

              .no-skew {
                align-content: center;
                align-items: flex-start;
                display: flex;
                padding: 35px 25px 35px 50px;
                transform: skewX(10deg);
                width: 100%;

                &.block {
                  display: block;
                }

                .image,
                .info {
                  float: left;
                }

                .image-holder {
                  display: flex;
                  flex-wrap: wrap;
                  width: 200px;

                  .image {
                    align-items: center;
                    border: 7px solid #fff;
                    border-radius: 50%;
                    display: flex;
                    flex-shrink: 0;
                    height: 175px;
                    justify-content: center;
                    margin: 0 30px 15px 0;
                    overflow: hidden;
                    width: 175px;

                    img {
                      height: auto;
                      width: 175px;
                    }
                  }

                  .sources {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    padding-right: 15px;

                    .icon-holder {
                      margin: 0 7px 7px 0;
                    }
                  }
                }

                .info {
                  width: auto;
                  .name-container {
                    margin-bottom: 7px;
                    display: flex;

                    h2 {
                      font-size: $forty;
                      font-weight: $extra-bold;
                      line-height: $thirty-two;
                      display: inline-block;
                      margin-bottom: 0;
                    }

                    .icon-container {
                      border: 1px solid $white-base;
                      border-radius: 50%;
                      display: inline-flex;
                      cursor: pointer;
                      margin: auto 0 auto 15px;
                      height: 30px;
                      width: 30px;

                      .icon {
                        margin: auto;
                        color: $white-base;
                        font-size: $ten;
                        height: $ten;
                        width: $ten;
                      }
                    }
                  }

                  p {
                    font-size: $sixteen;
                    margin: 0 0 7px;
                  }

                  .actions,
                  .secondaryInformation {
                    margin: 0;
                    padding: 0;

                    li {
                      list-style: none;

                      p,
                      h3 {
                        margin: 0;
                      }

                      i {
                        margin-left: $four;
                      }
                    }
                  }

                  .actions {
                    font-size: $fourteen;
                    font-weight: $extra-bold;
                    margin-bottom: 15px;

                    li {
                      color: $purple-lighter;
                      cursor: pointer;
                      display: inline-block;
                      margin-right: 15px;
                      transition: color 0.1s linear;

                      &:hover {
                        color: $white;
                      }
                    }

                    .zc-dropdown {
                      .zc-dropdown-opener {
                        border: none;
                        padding: 0;

                        .icon {
                          color: $white-base;
                        }
                      }

                      .zc-dropdown-results {
                        background-color: $black-dark;
                        top: 36px;

                        li {
                          &:first-of-type {
                            border-top: none;
                          }

                          &:hover {
                            background-color: $black-base;
                          }
                        }
                      }
                    }
                  }

                  .secondaryInformation {
                    display: flex;

                    li {
                      flex: 0 0 1;
                      border-left: 1px solid #fff;
                      padding: 0 15px;

                      &:first-of-type {
                        border: none;
                        padding-left: 0;
                      }

                      &:last-of-type {
                        max-width: 65%;
                      }

                      &:nth-of-type(4) {
                        flex: 1 0 0;
                      }

                      h3 {
                        font-size: $twelve;
                        font-weight: $extra-bold;
                        margin-bottom: 7px;
                      }

                      h4 {
                        font-size: $eighteen;
                        font-weight: $extra-bold;

                        .icon {
                          margin-left: 7px;
                        }

                        .dash-circle {
                          display: inline-block;
                          margin: 0 0 0 7px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-var-key {
  color: grey;
  font-size: 12px;
  padding-right: 2px;
  margin: 1px;
}

.custom-var-val {
  color: white;
  font-size: 10px;
  margin: 0px;
}

.header_options-dots {
  vertical-align: middle;
  text-align: center;
  color: #d2d2d2;
  font-size: $twenty-eight;
  border: 1px solid #d2d2d2;
  border-radius: 25px;

  i {
    width: 61px;
  }
}

@media only screen and (max-width: 1200px) {
  .modal
    .modal-dialog.player-page
    .modal-content
    .modal-body
    .basic-info-row
    .basic-info
    .no-skew
    .image {
    height: 125px;
    width: 125px;

    img {
      height: inherit;
      width: inherit;
    }
  }
}

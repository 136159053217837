@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .dash-circle {
                margin-left: 7px;
              }
            }
          }
        }
      }
    }
  }
}

// Typography Mixin
// ========================================

// Font Sizes
@mixin s-giga {
  @include rem(font-size, $s-giga);
}

@mixin s-mega {
  @include rem(font-size, $s-mega);
}

@mixin s-huge {
  @include rem(font-size, $s-huge);
}

@mixin s-large {
  @include rem(font-size, $s-large);
  @include rem(line-height, $base-line-height);
}

@mixin s-bigger {
  @include rem(font-size, $s-bigger);
  @include rem(line-height, $base-line-height);
}

@mixin s-big {
  @include rem(font-size, $s-big);
  @include rem(line-height, $base-line-loose);
}

@mixin s-medium {
  @include rem(font-size, $s-medium);
  line-height: normal;
}

@mixin s-base {
  @include rem(font-size, $s-base);
}

@mixin s-small {
  @include rem(font-size, $s-small);
  //@include rem(line-height, $base-line-small);
}

@mixin s-tiny {
  @include rem(font-size, $s-tiny);
  @include rem(line-height, $base-line-small);
}

// Font Styles
@mixin font-type($type, $style, $weight) {
  font-family: $type;
  font-style: $style;
  font-weight: $weight;
}

//Zicon font
@mixin set-zicon-font-props() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import '~Styles/general/_variables';

.position-list {
  margin-bottom: 2rem;

  .position-list-title {
    font-weight: 800;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border-bottom: 1px solid $grey-dark;
    background-color: $white;
    margin: 0;
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.zc-dropdown {
  cursor: pointer;
  position: relative;
  width: auto;

  .zc-dropdown-opener {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    align-items: center;
    border: 1px solid $grey-light;
    display: flex;
    font-style: italic;
    justify-content: space-between;
    outline: none;
    padding: 8px 20px;

    button {
      background: transparent;
      padding: 0;
      outline: none;
      border: none;
      color: inherit;
      font-family: inherit;
      font-style: inherit;
      flex-grow: 1;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:focus {
        @include outline();
      }
    }

    .caret-down {
      font-size: $twelve;
      height: $twelve;
      margin-left: 25px;
      width: $twelve;
    }
  }

  .zc-dropdown-results {
    display: none;

    button {
      background: transparent;
      padding: 0;
      outline: none;
      border: none;
      color: inherit;
      font-family: inherit;
      width: 100%;
      text-align: left;
    }

    button:focus {
      @include outline();
    }

    &.opened {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      border-top-left-radius: 0;
      border-top-right-radius: 0;

      background: $white-base;
      border: 1px solid $grey-light;
      border-top: 0;
      display: block;
      left: 0;
      list-style: none;
      max-height: 300px;
      overflow: scroll;
      padding: 0;
      position: absolute;
      top: 41px;
      width: 100%;
      z-index: 10;

      &.up {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        bottom: 22px;
        top: inherit;
      }
    }

    li {
      border-bottom: 1px solid $light-grey;
      padding: 7px 15px;
      width: 100%;

      &:first-of-type {
        border-top: 1px solid $light-grey;
      }

      &:hover {
        background-color: #f3f3f3;
      }

      &:last-of-type {
        border: none;
      }
    }
  }
}

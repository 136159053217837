@import '~Styles/general/_variables';

.navbar {
  &.main-nav {
    height: 65px;
    padding: 0 1 rem;
    top: 0;
    width: 100%;
    z-index: 10;

    .navbar-brand {
      margin-right: 0;

      .nav-main-logo {
        height: 2em;
      }
    }

    .nav-trial-label {
      font-size: $eighteen;
      color: $grey-dark;
      letter-spacing: 1px;
    }

    .separator {
      margin: auto 1rem;
      width: 1px;
      background: $black-dark;
      height: 100%;
      display: inline-block;
    }

    .nav-zmail-logo {
      height: 1.5em;
    }

    .nav-search-icon {
      height: 2em;
    }

    .collapse {
      height: inherit;

      .navbar-nav {
        height: inherit;

        .nav-item {
          align-items: center;
          display: flex;

          > .btn-logout {
            background-color: transparent;
            border: none;
            cursor: pointer;
          }

          > .nav-link {
            color: rgba(0, 0, 0, 0.7);
          }

          &:hover {
            > .nav-link {
              color: rgba(0, 0, 0, 0.9);
            }
          }

          &:last-of-type {
            border-left: #d0cbcb 1px solid;
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      height: 95%;
      max-width: 1200px;
      width: 95%;

      .modal-content {
        overflow: scroll;
        padding: 0;

        .modal-header {
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;

          align-items: center;
          background: $purple-gradient-dark;
          color: $white-base;
          justify-content: flex-start;
          margin: 0;
          padding: 0 45px;
          position: relative;

          h1 {
            font-size: $twenty;
            margin: 0;
          }

          .player-page-nav {
            list-style: none;
            margin: 0 0 0 60px;
            padding: 0;

            li {
              display: inline-block;
              padding: 20px 15px;

              &.active {
                background: rgba(255, 255, 255, 0.3);
                transform: skewX(-10deg);

                a {
                  transform: skewX(10deg);
                }
              }

              a {
                color: $white-base;
                display: inline-flex;
                font-size: $thirteen;
              }
            }
          }

          .closer {
            cursor: pointer;
            right: 15px;
            position: absolute;
            top: 22px;

            .icon {
              color: $white-base;
              font-size: $twenty;
              height: 20px;
              width: 18px;
            }
          }
        }

        .modal-body {
          background: $grey-light;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-bottom: 45px;

          .timeline {
            padding-top: 30px;
          }
        }
      }
    }
  }
}

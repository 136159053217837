@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.academics {
                .academics_holder {
                  margin-top: 7px;

                  h3 {
                    font-size: $fourteen;
                    font-weight: 400;
                    margin: 0;
                    padding-bottom: 7px;
                  }

                  .bottom {
                    border-top: 1px solid $white-med;
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 15px;

                    .academic {
                      align-items: center;
                      display: flex;
                      margin-bottom: 15px;
                      width: 100%;

                      .left {
                        p {
                          margin: 0;

                          &.bold {
                            font-weight: 700;
                          }
                        }
                      }

                      .icon {
                        margin-left: auto;
                      }
                    }
                  }

                  .national_preps {
                    &:last-of-type {
                      margin-left: auto;
                    }
                  }

                  .top {
                    align-items: center;
                    display: flex;
                    font-size: $sixteen;
                    justify-content: space-between;

                    p {
                      font-size: $forty;
                      line-height: $forty;
                      margin-right: 7px;

                      span {
                        font-size: $twenty-four;
                      }
                    }

                    .dash-circle,
                    .verified {
                      height: 25px;
                      margin-left: 5px;
                      width: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

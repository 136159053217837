@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            padding: 45px;

            .box {
              &.no-padding {
                padding: 0;
              }

              &.col-md-4 {
                flex: 0 0 32%;
                max-width: 32%;
              }

              &.col-md-6 {
                flex: 0 0 49%;
                max-width: 49%;
              }

              &.col-md-8 {
                flex: 0 0 66%;
                max-width: 66%;
              }

              .tab-link-container {
                cursor: pointer;
              }

              h2 {
                font-size: $twenty;
                font-weight: 900;
              }

              p {
                font-size: $thirteen;
              }
            }
          }
        }
      }
    }
  }
}

.custom-checkbox {
  label {
    margin: 0;

    [type='checkbox'] {
      position: absolute;
      clip: rect(1px, 1px, 1px, 1px);
      padding: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;

      &.white {
        & + span {
          &:before {
            background-color: $white-base;
            border: 1px solid $grey-dark;
          }
        }
      }

      & + span {
        font-size: $thirteen;
        font-weight: normal;
        color: $grey-dark;
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: 0;
        outline: none;
        padding: 0;
        position: relative;
        user-select: none;

        &:before {
          content: '';
          height: $eighteen;
          width: $eighteen;
          background-color: $grey-dark;
          display: inline-flex;
          margin-right: $four;
          cursor: pointer;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          pointer-events: auto;
          border: 1px solid $grey-dark;
        }
      }

      &:checked {
        & + span:before {
          content: '\f00c';
          font-family: 'Font Awesome 5 Pro';
          font-weight: 900;
          color: $white-base;
          background-color: $purple;
          font-size: $fifteen;
          line-height: $fifteen;
          text-align: center;
          box-shadow: none;
        }
      }

      &:disabled {
        & + span {
          color: $grey-light;
          cursor: auto;

          &:before {
            box-shadow: none;
            background: #ddd;
          }
        }
      }
    }
  }
}

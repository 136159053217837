@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .commit-info {
            align-items: center;
            background: $black-dark;
            color: $white-base;
            display: flex;
            justify-content: center;
            padding: 7px;

            .committed-info {
              align-items: center;
              display: flex;
              justify-content: center;
            }

            .status {
              border-left: 1px solid $white-base;
              margin-left: 30px;
              padding-left: 30px;

              h5 {
                font-size: $fourteen;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

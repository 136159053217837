@import '~Styles/general/_variables';

.stat-icon-holder {
  display: inline-flex;

  .icon {
    &.verified {
      background-color: inherit;
      color: $green-base;
      font-size: $fifteen;
      height: $fifteen;
      width: $fifteen;
      font-weight: $extra-bold;

      &.wilson {
        display: inline-block;
        background: url('~Assets/images/wilson.png') center no-repeat;
        background-size: contain;
      }

      &.checkmark {
        color: $green-base;
        font-size: $ten;
        width: $ten;
        height: $ten;
        margin-left: 5px;
      }
    }
  }
}

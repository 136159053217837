@import '~Styles/mixins/_misc';
@import '~Styles/general/_variables';

.prospects {
  .no-padding {
    padding: 0;
  }

  .checkbox.white:focus + label:before {
    @include outline();
  }

  .col-md-10 {
    background-color: $white-light;

    .with-sidebar {
      min-height: 77vh;
      padding: 50px;
      overflow: auto;

      .zc-virtualized-table {
        margin-bottom: 30px;

        .add-hide-cell {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          .adder,
          .hider,
          .unhider,
          .untracker {
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;

            align-items: center;
            cursor: pointer;
            display: flex;
            height: 25px;
            justify-content: center;
            width: 25px;
            .icon {
              padding: 0;
              border: none;
              background-color: transparent;
              margin: auto;
              text-align: center;
            }
          }

          .adder {
            border: 1px solid $green-base;
            margin-right: 7px;

            .add {
              color: $green-base;
              height: 10px;
              width: 10px;
              font-size: $ten;
            }
          }

          .hider {
            border: 1px solid $red-base;

            .x {
              color: $red-base;
              height: 12px;
              width: 12px;
              font-size: $twelve;
            }
          }

          .unhider {
            border: 1px solid $red-base;
            background-color: $red-base;

            .hidden {
              color: $white-base;
            }

            .hidden-no-circle {
              color: $white-base;
              font-size: $twelve;
              height: auto;
              width: auto;
            }
          }

          .untracker {
            border: 1px solid $green-base;
            margin-right: 7px;
            background-color: $green-base;

            .checkmark {
              color: $white-base;
              font-size: $ten;
              height: 10px;
              width: 11px;
            }
          }
        }

        .ReactVirtualized__Table {
          .ReactVirtualized__Table__headerRow {
            .ReactVirtualized__Table__headerColumn {
              &:first-of-type {
                margin-right: 10px;
              }
            }
          }
        }
      }

      .hidden-prospects-nav {
        align-items: center;
        background-color: $white-dark;
        display: flex;
        padding: 15px 25px 15px 50px;
        width: 100%;

        .headshot {
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

          background: $grey-dark;
          border: 1px solid $grey-dark;
          color: $white-base;
          flex-shrink: 0;
          height: 50px;
          justify-content: center;
          margin-right: 7px;
          width: 50px;
        }

        .info {
          align-items: center;
          display: flex;
          flex-wrap: wrap;

          p {
            margin: 0;
            width: 100%;
          }
        }

        .player-hider {
          margin-left: auto;

          .btn {
            padding: 10px 32px;
          }
        }
      }
      .ratings-hover {
        display: inline-flex;
        width: $thirteen;
        height: $thirteen;
        justify-content: center;
        border-radius: 50%;
        border-color: transparent;
        background-color: $grey-base;
        color: $grey-icon;
        margin-left: 5px;
        font-size: $eight;
        position: relative;
        top: 1px;

        i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.source-rating-item {
  .icon-holder {
    .icon {
      color: $black;
    }
  }
}

.tooltip-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $white-base;
  font-size: 12px;

  li {
    margin-bottom: 7px;

    &:last-of-type {
      margin: 0;
    }

    &.school-offer-logo {
      align-items: center;
      display: flex;

      .tiny-logo {
        margin-right: 7px;
      }
    }
  }

  .tiny-logo {
    img {
      width: 22.5px;
      height: 22.5px;
    }
  }
}

.nav-item {
  cursor: pointer;
}

.content-container {
  display: inline-block;
}

.school-logo {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 10px;
  vertical-align: middle;

  img {
    height: 40px;
  }
}

//
// Overall table container
//

.headshot {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;

  .image {
    border-radius: 50%;
    display: flex;
    flex: 0 0 50px;
    margin-right: 7px;
    position: relative;
    width: 50px;

    img {
      -webkit-clip-path: circle(42% at 50% 50%);
      clip-path: circle(42% at 50% 50%);

      align-self: baseline;
      max-height: 67px;
      width: 100%;
    }

    .icon-holder {
      color: $white-base;
      bottom: 0;
      position: absolute;
      right: 0;
    }
  }

  &.medium-headshot {
    height: 65px;
    width: 65px;

    img {
      width: 65px;
      clip-path: circle(42% at 50% 50%);
    }
  }

  &.is-juco {
    &:after {
      background-color: $purple;
      border: 1px solid $white-base;
      border-radius: 20em;
      content: 'J';
      color: $white-base;
      height: 18px;
      text-align: center;
      line-height: 1.3;
      font-size: 12px;
      width: 18px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .sources-hs-location {
    font-size: 9px;
    line-height: 10px;
    color: $black;
  }
}

// Sorting

th .sort-direction-container {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.ascending {
    .sort-by-ascending {
      border-bottom-color: $purple-base;
    }
  }

  &.descending {
    .sort-by-descending {
      border-top-color: $purple-base;
    }
  }
}
.sort-by-ascending,
.sort-by-descending {
  border: 4px solid transparent;
  content: '';
  display: inline-block;
  height: 0;
  // top: 50%;
  width: 0;
  position: relative;
  right: -8px;
}
.sort-by-ascending {
  border-bottom-color: $grey-dark;
  margin-top: 0px;
  display: inline-block;
}
.sort-by-descending {
  border-top-color: $grey-dark;
  margin-top: 5px;
  display: inline-block;
}

.sort-direction-container {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

div {
  &.player-updated-alert {
    width: 100%;
    position: absolute;
    z-index: 1;
  }
}

// Header component

.page-content-container {
  margin-top: 66px;
}

div {
  &.page-header-container {
    padding: 0;
  }
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  display: flex;
}

.prospect-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 200px;
}

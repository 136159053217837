%active-primary-button {
  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        background-color: $purple-base;
      }
    }
  }
}

%disabled-primary-button {
  &.disabled {
    background-color: $purple-base;
  }
}

%disabled-secondary-button {
  &.disabled {
    background-color: $purple-base;
  }
}

@import '~Styles/general/_variables';
@import '~Styles/functions/_svg-uri';

.tag-list {
  padding-top: 3px !important;
  li {
    &.tag-item {
      margin: 0;

      .tag {
        display: flex;
        align-items: center;
        background-color: $purple-base;
        border-color: $purple-base;
        border-radius: 4px;
        color: $white;
        padding: 5px 7px;
        font-size: 0.875rem;
        margin: 0 5px 5px 0;

        .tag-remove {
          i {
            color: $white-base;
            font-size: $twelve;
            height: $twelve;
            margin-left: 7px;
            width: $twelve;
          }
        }
      }
    }
  }
}

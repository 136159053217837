@import '~Styles/general/_variables';

.exporter {
  .btn {
    margin-left: 30px;
    padding: 10px 0;

    &.btn-link {
      margin-right: 64px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@import '~Styles/mixins/_misc';
@import '~Styles/extensions/_buttons';

.btn {
  border: none;
  color: $white;
  font-size: $fourteen;
  padding: 10px 64px;
  white-space: nowrap;

  &:active,
  &:focus {
    background: inherit;
    box-shadow: none !important;
    &.transparent {
      background: inherit !important;
    }
  }

  &.btn-primary {
    @extend %active-primary-button;
    @extend %disabled-primary-button;

    background-color: $purple;
  }

  &.btn-secondary {
    @extend %disabled-primary-button;

    background-color: $black-light;
    color: $white;
  }

  &.btn-muted {
    @extend %disabled-primary-button;

    background-color: $black-light;
  }

  &.btn-clear {
    @extend %disabled-primary-button;

    background: none;
    color: $grey-dark;
  }

  &.btn-grey {
    @extend %disabled-primary-button;

    background: $grey-dark;
    color: $white-base;
  }
}

[role='button'][aria-disabled='true'] {
  pointer-events: none !important;
  cursor: default !important;

  * {
    color: $grey-dark !important;
  }
}

button:focus:not(.focus-visible),
button[role='checkbox']:focus {
  @include outline();
}

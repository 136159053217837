@import '~Styles/general/_variables';

.save-filter-modal {
  h3 {
    font-size: $twenty;
    font-weight: $extra-bold;
    margin-bottom: 15px;
  }

  label {
    display: block;
    font-size: $sixteen;
    font-weight: $bold;
    margin-bottom: 0;
  }

  .input {
    background-color: $white-base;
    border: 1px solid $grey-light;
    display: block;
    margin-bottom: 15px;

    input {
      background-color: $white-base;
      border: none;
    }
  }
}

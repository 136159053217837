@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .box {
                &.less-padding {
                  padding: 15px 45px 30px;

                  .stats-header {
                    h2 {
                      margin: 0;
                    }
                  }
                }

                .stats-header {
                  align-items: center;
                  display: flex;
                  margin-bottom: 30px;
                  margin-top: 10px;

                  .toggler {
                    margin-left: auto;
                  }
                }

                .stats-body {
                  .event,
                  .type {
                    border-top: 1px solid $grey-light;
                    margin: 0;
                    padding: 30px 0;

                    &:last-of-type {
                      padding-bottom: 0;
                    }

                    .btn {
                      background: none;
                      color: $black-base;
                      display: flex;
                      letter-spacing: 0;
                      text-transform: inherit;

                      h3 {
                        font-size: $sixteen;
                        letter-spacing: 0;
                        margin: 0;
                        text-align: left;
                        width: 100%;

                        span {
                          font-weight: 400;
                          letter-spacing: 0;
                        }
                      }

                      .caret-down {
                        height: 13px;
                        margin-left: 7px;
                        width: 24px;
                      }
                    }

                    .collapse {
                      margin-top: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

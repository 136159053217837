@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .box {
                &.other-sports {
                  .other-sports {
                    &__header {
                      padding-top: 15px;
                    }

                    &__body {
                      color: $purple-base;

                      &__sport {
                        border: none;
                        padding: 0;

                        &__header {
                          align-items: center;
                          display: flex;
                          font-weight: $bold;
                          margin-bottom: 15px;

                          .icon-holder {
                            margin-right: 7px;
                          }
                        }

                        &__body {
                          table {
                            tbody {
                              tr {
                                border-bottom: 1px solid #dedede;
                                border-top: 1px solid #dedede;

                                &:first-of-type {
                                  border-top: none;
                                }

                                &.no-border {
                                  border: none;

                                  &.odd {
                                    background-color: #f9f9f9;
                                  }
                                }

                                td {
                                  .verified {
                                    background-color: transparent;
                                  }

                                  .icon {
                                    &.caret-down {
                                      -webkit-transition: transform 350ms;
                                      -moz-transition: transform 350ms;
                                      -ms-transition: transform 350ms;
                                      -o-transition: transform 350ms;
                                      transition: transform 350ms;

                                      color: $grey-darkest;
                                      margin-left: auto;
                                    }

                                    &.flip {
                                      -webkit-transition: transform 350ms;
                                      -moz-transition: transform 350ms;
                                      -ms-transition: transform 350ms;
                                      -o-transition: transform 350ms;
                                      transition: transform 350ms;

                                      transform: rotate(-180deg);
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.exporter {
  .btn {
    margin-left: 30px;
    padding: 10px 0;

    &.btn-link {
      margin-right: 64px;

      + .hidden {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
    .downloader {
      .downloadWithBox {
        color: $white-base;
        font-size: $fourteen;
      }
    }
  }
}

@import '~Styles/general/_variables';

.login-page {
  &.forgot-password {
    .col-md-6 {
      &.right {
        h3 {
          margin-bottom: 7px;
        }
        + p {
          margin-bottom: $twenty-four;
        }
      }
    }
  }

  &.password-sent {
    .col-md-6 {
      &.right {
        text-align: center;

        .icon {
          height: 60px;
          margin-bottom: 30px;
          width: 60px;
        }

        p {
          &:first-of-type {
            border-bottom: 1px solid $light-grey;
            margin-bottom: 15px;
            padding-bottom: 15px;
          }

          &.below {
            font-size: $fourteen;
          }
        }
      }
    }
  }

  .temp-password {
    display: none;
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .measureables,
              .overview {
                .row {
                  margin-top: 30px;

                  h3,
                  p {
                    width: 100%;
                  }

                  h3 {
                    font-size: $sixteen;
                    font-weight: 400;
                  }

                  p {
                    align-items: baseline;
                    display: flex;
                    flex-grow: 0;
                    flex-shrink: 0;
                    font-size: $twenty-four;
                    font-weight: 700;
                    line-height: 90%;
                    margin-bottom: 0.5rem;

                    .small {
                      font-size: $twenty;
                      font-weight: 700;
                    }

                    .extra-small {
                      font-size: $fourteen;
                    }

                    i {
                      margin-left: $four;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.video-page {
              .box-holder {
                .box {
                  display: flex;
                  padding: 0;

                  .video {
                    flex: 0 0 65%;
                    min-height: 435px;
                    padding: 30px;

                    h2 {
                      margin-bottom: 20px;
                    }

                    .external-source-bg {
                      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
                        url('~Assets/images/LandingPage/hero-image.png');
                      background-position: top;
                      height: 433px;
                      width: 660px;

                      p {
                        background: rgba(0, 0, 0, 0.7);
                        height: 100%;
                        width: 100%;
                      }
                    }

                    .video-details {
                      .video-details-row {
                        display: flex;
                        flex-wrap: nowrap;
                        padding: 10px 0;

                        .video-location-date-container {
                          display: flex;
                          flex-wrap: wrap;

                          .location {
                            margin-right: 10px;
                          }

                          .date {
                            margin-right: 0;
                          }
                        }

                        .video-link-container {
                          display: flex;
                          flex-wrap: wrap-reverse;
                        }
                      }
                    }
                  }

                  .playlist {
                    display: flex;
                    flex: 0 0 35%;
                    flex-direction: column;
                    max-height: 600px;
                    overflow: scroll;

                    .playerlist_header {
                      background-color: $black-dark;
                      margin: 0;
                      padding: 20px 30px;

                      h2 {
                        color: $white-base;
                        font-size: $eighteen;
                        font-weight: 600;
                        margin: 0;
                      }
                    }

                    .playlist-body {
                      background-color: $black-medium;
                      color: $white-base;
                      flex-grow: 1;

                      .playlist-body-member {
                        align-items: center;
                        color: $white-base;
                        cursor: pointer;
                        display: flex;
                        padding: 15px 30px;
                        width: 100%;

                        .icon-holder {
                          background: transparent;
                          border: 1px solid $white-base;
                          height: 35px;
                          margin-right: 15px;
                          width: 35px;

                          .play {
                            text-align: center;
                            font-size: $fourteen;
                            width: $fourteen;
                            height: $fourteen;
                            color: $white-base;
                          }
                        }

                        .info {
                          width: 100%;

                          .title {
                            align-items: center;
                            display: flex;
                            justify-content: space-between;

                            h5 {
                              margin: 0;
                            }

                            i {
                              margin-left: 15px;
                            }

                            p {
                              margin-top: $eight;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

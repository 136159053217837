@import '~Styles/general/_variables';

.tooltip-list {
  text-align: left;

  &.sources {
    .source-rating-item {
      > a,
      > span {
        align-items: center;
        color: $grey-dark;
        display: flex;
        font-size: $thirteen;
      }
    }
  }
}

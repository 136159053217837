@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.twitter-preview {
                h2 {
                  div {
                    display: inline-block;
                  }
                }
                .block-inner {
                  .other-schools {
                    .carousel-rankings-content {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;

                      .school-block {
                        padding: 0;
                      }
                    }
                  }
                }

                .smaller {
                  font-size: $eighteen;
                  font-weight: 600;
                  margin-left: 7px;
                }

                .my-school {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  .carousel-rankings-item {
                    .rankings-icon-total-container {
                      right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

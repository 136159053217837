@font-face {
  font-family: 'zicon';
  src: url('../../assets/fonts/zicon/zicon.eot?h6wa3k');
  src:
    url('../../assets/fonts/zicon/zicon.eot?h6wa3k#iefix') format('embedded-opentype'),
    url('../../assets/fonts/zicon/zicon.ttf?h6wa3k') format('truetype'),
    url('../../assets/fonts/zicon/zicon.woff?h6wa3k') format('woff'),
    url('../../assets/fonts/zicon/zicon.svg?h6wa3k#zicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='zicon-'],
[class*=' zicon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zicon-hudl:before {
  content: '\e90a';
}
.zicon-rivals-camp:before {
  content: '\e901';
  color: #fff;
}
.zicon-rivals:before {
  content: '\e902';
}
.zicon-rcx:before {
  content: '\e903';
}
.zicon-ratings-247:before {
  content: '\e904';
}
.zicon-pb3:before {
  content: '\e905';
  color: #fff;
}
.zicon-nike-opening:before {
  content: '\e906';
}
.zicon-ncsa:before {
  content: '\e907';
}
.zicon-national-preps:before {
  content: '\e908';
}
.zicon-national-combine:before {
  content: '\e909';
}
.zicon-athletic:before {
  content: '\e90b';
}

@import '~Styles/general/_variables';

.filter {
  &.location-radius {
    .collapse,
    .collapsing {
      position: relative;

      .btn {
        color: $white-base;
        display: block;
        font-size: 0.75rem;
        padding: 7px 4px;
        text-align: center;
      }
    }

    .location-radius__holder {
      align-items: center;
      display: flex;
      font-size: $thirteen;
      justify-content: space-between;
      margin-top: 7px;
      width: 80%;

      input {
        font-size: $fourteen;
        width: 33%;
      }
    }

    .search-results {
      background: $black-light;
      max-height: 300px;
      overflow: scroll;
      position: absolute;
      top: 38px;
      width: 100%;
      z-index: 100;

      .result {
        padding: 7px 15px 7px 20px;
        position: relative;

        &.selected {
          background: $grey-dark;
          color: $white-base;

          .name {
            .icon {
              background-color: $purple-base;
              left: 5px;
              position: absolute;
              top: 11px;
            }
          }
        }

        .name {
          font-size: $twelve;
          margin: 0;
          padding: 0;
        }

        .alert {
          margin: 15px 30px;
          padding: 7px 15px;
        }
      }

      .alert {
        background: none;
        border: none;
        margin: 0;

        &.alert-danger,
        &.alert-warning {
          color: $grey-dark;
        }
      }
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .box-holder {
            .box {
              &.offerVisits {
                padding: 7px 15px 0;
              }

              .nav-header {
                .col-md-auto {
                  font-size: $twenty-four;
                  font-weight: $extra-bold;
                  padding: 7px 45px;

                  &.active {
                    border-bottom: 5px solid $purple-base;
                  }

                  &.inactive {
                    cursor: pointer;
                  }
                }
              }

              .offers-list {
                padding: 30px 15px;

                .col-md-1 {
                  margin-bottom: 15px;

                  .school-image {
                    height: 65px;
                    padding: 13px;
                    width: 65px;

                    img {
                      width: 40px;
                    }
                  }
                }
              }
              .official-visits {
                align-items: center;
                background-color: $white-light;
                padding: 30px;

                .boldest {
                  padding: 0 0 0 15px;
                }

                .col-md-10 {
                  padding: 0;
                }

                .col-md-auto {
                  &.single-official-visit {
                    .school-image {
                      margin: 0;
                      width: 75px;

                      img {
                        width: 55px;
                      }
                    }

                    .empty-icon {
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      border-radius: 50%;

                      border: 1px dashed $grey-dark;
                      height: 75px;
                      width: 75px;
                    }
                  }
                }
              }

              .timeline-holder {
                .timeline {
                  flex-wrap: wrap;

                  .school-image-holder {
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    p {
                      margin: 0;
                    }

                    .school-image {
                      height: auto;
                      margin-right: 7px;
                      width: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.offer-clickable-tooltip-container {
  cursor: pointer;
}

@import '~Styles/general/_variables';

.position-list-player-row-wrapper {
  padding: 0.5rem 2rem;
  background-color: $white;

  &:not(:last-child) {
    .position-list-player-row {
      border-bottom: 1px solid $grey-light;
    }
  }

  .position-list-player-row {
    display: flex;
    justify-content: space-between;

    .position-list-player-info {
      display: flex;
      align-items: center;
      font-size: 0.875rem;

      .headshot {
        width: initial;
      }

      .position-list-player-info-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-weight: 600;
        font-size: 0.875rem;

        .position-list-player-data,
        .position-list-player-name {
          margin: 0;
          flex: 0 0 100%;
          font-size: inherit;
        }
      }
    }

    .position-list-button-container {
      display: flex;
      align-items: center;

      .position-list-profile-button {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
}

$dangerForegroundColor: #5e171f;
$dangerBackgroundColor: #f9dcdd;

$infoForegroundColor: #0a4852;
$infoBackgroundColor: #d4edf2;

$successForegroundColor: #124a1e;
$successBackgroundColor: #dbf0e0;

$warningForegroundColor: #533d04;
$warningBackgroundColor: #fff5d6;

.alert {
  &.alert {
    &-danger {
      color: $dangerForegroundColor;
      background-color: $dangerBackgroundColor;
    }

    &-info {
      color: $infoForegroundColor;
      background-color: $infoBackgroundColor;
    }

    &-success {
      color: $successForegroundColor;
      background-color: $successBackgroundColor;
    }

    &-warning {
      color: $warningForegroundColor;
      background-color: $warningBackgroundColor;
    }
  }
}

@import '~Styles/general/_variables';

.modal {
  .modal-dialog {
    &.player-page {
      .modal-content {
        .modal-body {
          .secondary-page {
            &.stats-page {
              .stats-body {
                .event {
                  .stat-holder {
                    .stat {
                      display: flex;
                      padding: $fourteen;

                      &.grey {
                        background-color: $grey-base;

                        .info-tooltip-icon {
                          border: 1px solid $grey-dark;
                        }
                      }

                      .stat-icon-label {
                        display: flex;
                        width: 60%;

                        .stat-label {
                          font-weight: bold;
                          margin-left: $twelve;
                        }

                        .stat-verified-holder {
                          padding-top: $four;
                          width: auto;
                          height: auto;
                        }
                      }

                      .star {
                        color: $grey-dark;
                        margin-left: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

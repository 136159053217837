@import '~Styles/general/_variables';

.filter {
  &.date {
    .collapse,
    .collapsing {
      .btn {
        color: $white-base;
        display: block;
        font-size: $twelve;
        padding: 7px 4px;
        text-align: center;
      }
    }
  }
}

.popover {
  border-radius: 0 !important;

  &.date-custom-input {
    background-color: $black-dark;
    max-width: 500px;
    padding: 10px;
    font-size: 16px;

    .arrow {
      &:after {
        border-right-color: $black-dark;
      }
    }

    .DayPickerInput {
      input {
        max-width: 150px;
      }
    }
  }
}

.date-picker__input {
  max-width: 150px;

  .DayPickerInput-Overlay {
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 344px;
  }

  &.date-picker__input--from {
    .DayPickerInput-Overlay {
      margin-left: -22px;
    }
  }

  &.date-picker__input--to {
    .DayPickerInput-Overlay {
      margin-left: -172px;
    }
  }
}
